import React, {useCallback, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import cx from 'classnames';
import {Button} from '~library/atoms/Button';
import {Typography} from '~library/atoms/Typography';
import {Input} from '~library/atoms/Input';
import {selectAddress, selectLocation} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectShowWhere} from '~/SignUpSheets/reducers/blueprint/selectors';
import {AddressOverlay} from '~/SignUpSheets/components/AddressOverlay/AddressOverlay';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {DetailsTimezone} from '~/SignUpSheets/components/Details/components/DetailsTimezone';
import {useOnChangeAndUpdateSheet} from '~/SignUpSheets/hooks/useOnChangeAndUpdateSheet';
import {useViewOnlyMode} from '~/SignUpSheets/components/Details/ViewOnlyModeProvider';
import {DetailsWhereViewOnly} from './DetailsWhereViewOnly';

export const DetailsWhere = () => {
  const where = useSelector(selectLocation);
  const {street, city, state, zip} = useSelector(selectAddress, shallowEqual);
  const showWhere = useSelector(selectShowWhere);
  const isDesktop = useIsLargishAndUp();
  const [onDetailsChange] = useOnChangeAndUpdateSheet();
  const viewOnlyMode = useViewOnlyMode();
  const hasAddress = Boolean(street || city || state || zip);

  const [addressOverlayOpen, setAddressOverlayOpen] = useState(false);

  const openAddressOverlay = useCallback(
    () => setAddressOverlayOpen(true),
    [setAddressOverlayOpen]
  );

  const closeAddressOverlay = useCallback(() => {
    setAddressOverlayOpen(false);
  }, [setAddressOverlayOpen]);

  const renderedAddress = useMemo(() => {
    if (!hasAddress) return '';

    const sameLine = [city, state, zip].filter(Boolean).join(' ');

    return (
      <>
        {street}
        <br />
        {sameLine}
      </>
    );
  }, [where, street, city, state, zip, hasAddress]);

  if (!showWhere) return null;

  if (viewOnlyMode) {
    return <DetailsWhereViewOnly />;
  }

  return (
    <div
      className={cx('signup-sheet__details__where', {
        'signup-sheet__details-content__group--bigger-gap': !isDesktop,
        'signup-sheet__details__bottom__spacing--smaller-gap': isDesktop,
      })}
    >
      {isDesktop && (
        <Typography
          variant="label2"
          size="small"
          className="signup-sheet__details__default-label"
          color="coin"
        >
          Where
        </Typography>
      )}

      <Input
        id="location"
        name="location"
        data-qa-id="event-location"
        error={null}
        type="text"
        value={where ?? ''}
        onChange={onDetailsChange}
        live
        placeholder="Add location name"
        showOptionalLabel
        inputClassName="details__font__where-location"
      />

      <Button
        variant="unstyled-button"
        onClick={openAddressOverlay}
        className={cx('input-text', 'live', 'details__when-where__input', 'details__font-default', {
          placeholder: !hasAddress,
        })}
        data-qa-id="sheet-where"
      >
        {hasAddress ? renderedAddress : 'Add address'}
      </Button>

      <DetailsTimezone />

      <AddressOverlay isOpen={addressOverlayOpen} closeOverlay={closeAddressOverlay} />
    </div>
  );
};
