import kebabCase from 'lodash/kebabCase';
import Tooltip from 'rc-tooltip';
import {useSelector} from 'react-redux';
import {Input} from '~library/atoms/Input';
import {Checkbox} from '~library/atoms/Checkbox';
import {Typography} from '~library/atoms/Typography';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';
import {SheetOption} from './SheetOption';

export const SheetOptionWithToggle = ({
  title,
  description,
  onToggle,
  onCheck,
  checked,
  toggled,
  hideCheckbox,
  disabled,
}) => {
  const dataQaId = title ? `sheet-option-${kebabCase(title)}` : '';
  const sheetId = useSelector(selectSheetId);

  const sheetOptionContent = (
    <SheetOption
      title={title}
      description={description}
      headerAction={
        <Input
          type="toggle"
          checked={toggled}
          onChange={onToggle}
          data-qa-id={`${dataQaId}-toggle`}
          size="small"
          disabled={disabled}
        />
      }
    >
      {!hideCheckbox && (
        <Checkbox
          checked={checked}
          onChange={onCheck}
          data-qa-id={`${dataQaId}-required`}
          label="Required"
          labelVariant="paragraph3"
          disabled={disabled}
        />
      )}
    </SheetOption>
  );

  if (!sheetId) {
    return (
      <Tooltip
        placement="top"
        trigger={['click', 'hover']}
        overlay={
          <Typography variant="paragraph2">
            To access these tools, please save your draft
          </Typography>
        }
        overlayClassName="sheet-option__tooltip"
      >
        <span>{sheetOptionContent}</span>
      </Tooltip>
    );
  }

  return sheetOptionContent;
};
