import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {CloseLineIcon} from '~library/svg/CloseLineIcon';
import {ResponsiveModal} from '~library/organisms/ResponsiveModal';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetOption} from '~/SignUpSheets/components/Header/components/SheetOptions/components/SheetOption/SheetOption';

export const CancelSheet = () => {
  const sheetId = useSelector(selectSheetId);
  const {cancelSheet} = useSheetActions();
  const {closeOptions} = useSheetOptions();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onConfirmCancel = useCallback(() => {
    cancelSheet(sheetId);
    setShowConfirmModal(false);
    closeOptions();
  }, [sheetId, cancelSheet, closeOptions]);

  const handleCloseConfirmModal = useCallback(() => {
    setShowConfirmModal(false);
  }, []);

  const onCancelSheet = useCallback(() => {
    setShowConfirmModal(true);
  }, []);

  return (
    <>
      <SheetOption
        title="Cancel Sheet"
        icon={<CloseLineIcon ratio={0.75} className="sheet-options__cancel-sheet-icon" />}
        onClick={onCancelSheet}
        className="sheet-options__cancel-sheet-button"
        noBorder
      />

      {showConfirmModal && (
        <ResponsiveModal
          isOpen
          title="Cancel sheet"
          body="Your SignUp Sheet will be canceled and volunteers will be notified via email."
          primaryText="Cancel sheet"
          secondaryText="Nevermind"
          primaryAction={onConfirmCancel}
          onClose={handleCloseConfirmModal}
        />
      )}
    </>
  );
};
