import {useCallback} from 'react';
import {EditLineIcon} from '~library/svg/EditLineIcon';
import {useIsLargeAndUp} from '~/common/hooks/useMatchQuery';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetOption} from '~/SignUpSheets/components/Header/components/SheetOptions/components/SheetOption/SheetOption';

export const EditSheet = () => {
  const {editSheet} = useSheetActions();
  const isLargeAndUp = useIsLargeAndUp();
  const {closeOptions} = useSheetOptions();

  const onEditSheet = useCallback(() => {
    editSheet();
    closeOptions();
  }, [editSheet, closeOptions]);

  return (
    <SheetOption
      title={isLargeAndUp ? 'Edit Signup Sheet' : 'Edit sheet'}
      description="Add slots, edit signup details and remove volunteers."
      icon={<EditLineIcon ratio={0.5} />}
      onClick={onEditSheet}
    />
  );
};
