import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  selectBlueprintId,
  selectSignupOptionGroups,
  selectSignupOptions,
  selectSignups,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {PreviewMessage} from './PreviewMessage/PreviewMessage';
import {SelectVolunteers} from './SelectVolunteers/SelectVolunteers';
import {processData} from './SelectVolunteers/utils/processData';
import {WriteMessage} from './WriteMessage/WriteMessage';

const getAllVolunteers = (groupedData) => {
  const volunteers = [];

  groupedData.forEach((group) => {
    if (group.volunteers) {
      volunteers.push(...group.volunteers);
    }

    if (group.subGroups) {
      group.subGroups.forEach((subGroup) => {
        if (subGroup.volunteers) {
          volunteers.push(...subGroup.volunteers);
        }
      });
    }
  });

  return volunteers;
};

export const Steps = ({onClose, currentStep, setCurrentStep, message, setMessage}) => {
  const messageVolunteersRef = useRef(null);
  const signups = useSelector((state) => selectSignups(state));
  const signupOptions = useSelector((state) => selectSignupOptions(state));
  const groups = useSelector((state) => selectSignupOptionGroups(state));
  const blueprintId = useSelector((state) => selectBlueprintId(state));
  const [selectedGroups, setSelectedGroups] = useState(new Set());
  const [selectedUserIds, setSelectedUserIds] = useState(new Set());
  const [hasInitialized, setHasInitialized] = useState(false);

  const groupedData = useMemo(
    () => processData(blueprintId, signupOptions, signups, groups),
    [blueprintId, signupOptions, signups, groups]
  );

  const allVolunteers = useMemo(() => getAllVolunteers(groupedData), [groupedData]);

  // Generate volunteersMap once when component mounts
  const volunteersMap = useMemo(() => {
    const newMap = new Map();

    allVolunteers.forEach((volunteer) => {
      newMap.set(volunteer.userId, {
        name: volunteer.name,
        email: volunteer.email,
      });
    });

    return newMap;
  }, [allVolunteers]);

  // Initialize selected volunteers once
  useEffect(() => {
    if (!hasInitialized && allVolunteers.length > 0) {
      const userIds = new Set(allVolunteers.map((volunteer) => volunteer.userId));
      setSelectedUserIds(userIds);
      setHasInitialized(true);
    }
  }, [allVolunteers, hasInitialized]);

  useEffect(() => {
    if (messageVolunteersRef.current) {
      messageVolunteersRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const handleNext = useCallback(
    () => setCurrentStep((prev) => Math.min(prev + 1, 3)),
    [setCurrentStep]
  );

  const handleBack = useCallback(
    () => setCurrentStep((prev) => Math.max(prev - 1, 1)),
    [setCurrentStep]
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <SelectVolunteers
            onNext={handleNext}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
            volunteersMap={volunteersMap}
            groupedData={groupedData}
          />
        );
      case 2:
        return (
          <WriteMessage
            onBack={handleBack}
            onNext={handleNext}
            selectedUserIds={selectedUserIds}
            volunteersMap={volunteersMap}
            message={message}
            setMessage={setMessage}
          />
        );
      case 3:
        return (
          <PreviewMessage
            onBack={handleBack}
            message={message}
            selectedUserIds={selectedUserIds}
            volunteersMap={volunteersMap}
            onClose={onClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="message-volunteers" ref={messageVolunteersRef}>
      {renderStepContent()}
    </div>
  );
};
