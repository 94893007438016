import {useCallback, useMemo, useState} from 'react';
import {Checkbox} from '~library/atoms/Checkbox';
import {Typography} from '~library/atoms/Typography';
import {ChevronIcon} from '~library/svg/ChevronIcon';
import {VolunteerItem} from './VolunteerItem';

import './SlotGroup.sass';

export const SlotGroup = ({group, index, selectedUserIds, onGroupSelect, onVolunteerSelect}) => {
  const [isExpanded, setIsExpanded] = useState(index === 0);
  const [title, subTitle] = group.title.split('|');

  console.log('debugHere', {title, subTitle});

  const volunteers = useMemo(() => {
    if (group.subGroups && group.subGroups.length > 0) {
      return group.subGroups.flatMap((subGroup) => subGroup.volunteers);
    }
    return group.volunteers || [];
  }, [group]);

  const allVolunteersSelected = volunteers.every((v) => selectedUserIds.has(v.userId));
  const someVolunteersSelected = volunteers.some((v) => selectedUserIds.has(v.userId));

  const handleHeaderClick = useCallback((e) => {
    // Prevent toggling expansion when clicking the checkbox
    if (!e.target.closest('.checkbox')) {
      setIsExpanded((prev) => !prev);
    }
  }, []);

  const handleGroupSelect = useCallback(() => {
    if (allVolunteersSelected || someVolunteersSelected) {
      const volunteersToDeselect = volunteers.filter((v) => selectedUserIds.has(v.userId));
      onGroupSelect(volunteersToDeselect);
    } else {
      onGroupSelect(volunteers);
    }
  }, [volunteers, onGroupSelect, allVolunteersSelected, someVolunteersSelected, selectedUserIds]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        handleGroupSelect();
      }
    },
    [handleGroupSelect]
  );

  return (
    <div className="message-volunteers__slot-group">
      <div
        data-qa-id={`message-volunteers-group-header-${group.id}`}
        className="message-volunteers__slot-header"
        onClick={handleHeaderClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <Checkbox
          checked={allVolunteersSelected || someVolunteersSelected}
          onChange={handleGroupSelect}
          data-qa-id={`message-volunteers-group-checkbox-${group.id}`}
          blueCheckbox
        />
        <Typography variant="list1" className="message-volunteers__slot-title">
          <span className="semibold-text">
            {title}
            {subTitle ? ' |' : ''}
          </span>{' '}
          {subTitle}
        </Typography>
        <ChevronIcon
          className="message-volunteers__slot-icon"
          ratio={0.5}
          direction={isExpanded ? 'up' : 'down'}
        />
      </div>
      {isExpanded && volunteers.length > 0 && (
        <div className="message-volunteers__volunteers-list">
          {volunteers.map((volunteer) => (
            <VolunteerItem
              key={volunteer.userId}
              volunteer={volunteer}
              isSelected={selectedUserIds.has(volunteer.userId)}
              onSelect={() => onVolunteerSelect(volunteer)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
