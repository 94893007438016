import cx from 'classnames';
import {MessagesLineIcon} from '~library/svg/MessagesLineIcon';
import {Typography} from '~library/atoms/Typography';

import {pebble} from '~libSass/base/_exports.sass';
import './Comment.sass';

export const Comment = ({comment, className}) =>
  comment && (
    <div className={cx('signup-comment', className)}>
      <MessagesLineIcon className="signup-comment__icon" color={pebble} ratio={0.5} />
      <Typography variant="paragraph4" color="pebble">
        {`You commented: ”${comment}”`}
      </Typography>
    </div>
  );
