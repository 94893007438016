import {useSelector} from 'react-redux';
import {selectWishlistOption, selectWishlists} from '~/SignUpSheets/reducers/sheet/selectors';
import {
  addAffiliateTagsToWishlist,
  getBrandNameAndIcon,
  normalizeUrl,
} from '~/SignUpSheets/utils/misc';
import {VendorDisc} from '~library/molecules/VendorDisc';
import {selectBlueprintId} from '~/SignUpSheets/reducers/blueprint/selectors';
import {Typography} from '~library/atoms/Typography';
import {useMatchQuery} from '~library/hooks/useMatchQuery';

import {largishAndUpQuery} from '~libSass/base/_exports.sass';

export const WishList = () => {
  const wishlistOption = useSelector(selectWishlistOption);
  const blueprintId = useSelector(selectBlueprintId);
  const wishlists = useSelector(selectWishlists);
  const {matches: isDesktop} = useMatchQuery(`(${largishAndUpQuery})`);
  const isCustomWishlist = wishlistOption === 'my-wishlist';

  return (
    <div className="confirm-page__section">
      <div>
        <Typography
          className="confirm-page__title"
          variant={isDesktop ? 'header2' : 'header3'}
          as="h2"
        >
          {isCustomWishlist
            ? 'Get something from their wishlist'
            : 'Need something for your signup?'}
        </Typography>
        {isCustomWishlist && (
          <Typography className="confirm-page__subtitle" variant="paragraph2" color="pebble">
            Your organizer added a wishlist—check it out!
          </Typography>
        )}
      </div>

      {isCustomWishlist ? (
        <div className="confirm-page__vendor-discs">
          {wishlists.map((wishlist) => {
            const {brandName, iconUrl} = getBrandNameAndIcon(wishlist);

            return (
              <VendorDisc
                key={wishlist}
                imgSrc={iconUrl}
                displayText="View wishlist"
                vendorName={brandName}
                className={`vendor-disc__wrapper--${brandName.toLowerCase() || 'default'}`}
                dataQaId={`${brandName}-vendor-disc`}
                href={addAffiliateTagsToWishlist(normalizeUrl(wishlist || '#'), blueprintId)}
                textColor="tanzanite"
                semibold
              />
            );
          })}
        </div>
      ) : (
        <VendorDisc
          imgSrc="https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Amazon@2x.png"
          href={addAffiliateTagsToWishlist('https://www.amazon.com/', blueprintId, false)}
          displayText="Shop now"
          vendorName="Amazon"
          textColor="tanzanite"
          semibold
          size="large"
        />
      )}
    </div>
  );
};
