import {Button} from '~library/atoms/Button';
import './Footer.sass';

export const Footer = ({
  onBack,
  onNext,
  nextLabel = 'Next',
  nextDisabled = false,
  showBack = true,
}) => (
  <div className="message-volunteers__footer">
    {showBack && (
      <Button
        variant="secondary"
        onClick={onBack}
        className="message-volunteers-modal-back-button"
        data-qa-id="message-volunteers-modal-back-button"
        size="large"
      >
        Back
      </Button>
    )}
    <Button
      variant="primary"
      onClick={onNext}
      className="message-volunteers-modal-next-button"
      data-qa-id="message-volunteers-modal-next-button"
      size="large"
      disabled={nextDisabled}
    >
      {nextLabel}
    </Button>
  </div>
);
