import {createContext, useCallback, useContext, useState, useMemo} from 'react';

const SheetOptionsContext = createContext(null);

export const SheetOptionsProvider = ({
  children,
  trackChangeUpstream,
  onCopyShareableLink,
  onGetPrintableSheet,
}) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const toggleOptions = useCallback(() => {
    setOptionsOpen((o) => !o);
  }, []);

  const closeOptions = useCallback(() => {
    setOptionsOpen(false);
  }, []);

  const openOptions = useCallback(() => {
    setOptionsOpen(true);
  }, []);

  const value = useMemo(
    () => ({
      optionsOpen,
      openOptions,
      toggleOptions,
      closeOptions,
      trackChangeUpstream,
      onCopyShareableLink,
      onGetPrintableSheet,
    }),
    [
      optionsOpen,
      openOptions,
      toggleOptions,
      closeOptions,
      trackChangeUpstream,
      onCopyShareableLink,
      onGetPrintableSheet,
    ]
  );

  return <SheetOptionsContext.Provider value={value}>{children}</SheetOptionsContext.Provider>;
};

export const useSheetOptions = () => {
  const context = useContext(SheetOptionsContext);
  if (!context) {
    throw new Error('useSheetOptions must be used within a SheetOptionsProvider');
  }
  return context;
};
