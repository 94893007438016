import cx from 'classnames';
import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from '~library/atoms/Link';
import {Typography} from '~library/atoms/Typography';
import {LinkIcon} from '~library/svg/LinkIcon';
import {useIsLargishAndUp} from '~library/hooks/useMatchQuery';
import {toTitleCase} from '~/common/fabric/CreateDesign/components/CardEditor/actions/TextFormat/components/Casing/Casing';
import {generateDisplayText} from '~/SignUpSheets/utils/affiliate';
import AmazonIcon from '../../../../images/logos/amazon-logo-v2@2x.png';

import {tanzanite} from '~libSass/base/_exports.sass';
import './LinkPreview.sass';

export const LinkPreview = ({
  href = '',
  displayText = generateDisplayText(href) || 'Link',
  image,
  vendor,
  title = '',
  titleVariant = 'paragraph3',
  titleColor = 'charcoal',
  subtitle,
  subtitleVariant = 'paragraph4',
  className,
  imgClassName,
  disabled = false,
  identifiedProduct = false,
  compact = false,
  DisclaimerPopover,
  endAdornment,
  stackEndAdornment = false,
}) => {
  const isLargishAndUp = useIsLargishAndUp();

  const SubtitleView = useMemo(
    () =>
      subtitle || (vendor === 'amazon' && identifiedProduct && title) ? (
        <div className="link-preview__subtitle">
          {subtitle && <Typography variant={subtitleVariant}>{subtitle}</Typography>}
          {vendor === 'amazon' && identifiedProduct && title && (
            <div className="link-preview__amazon-tag">
              <Typography variant="paragraph4">Available on</Typography>
              <img src={AmazonIcon} alt="Amazon" className="link-preview__amazon-tag__logo" />
            </div>
          )}
        </div>
      ) : null,
    [subtitle, subtitleVariant, vendor, title, identifiedProduct]
  );

  const MAX_TITLE_LENGTH_TO_SHOW = useMemo(() => (isLargishAndUp ? 118 : 60), [isLargishAndUp]);
  const truncateTitle = useMemo(
    () => identifiedProduct && title.length > MAX_TITLE_LENGTH_TO_SHOW,
    [identifiedProduct, title, MAX_TITLE_LENGTH_TO_SHOW]
  );

  return (
    <div
      className={cx('link-preview__wrapper', {
        [className]: !!className,
        'identified-product': identifiedProduct,
        compact,
        disabled,
        [vendor]: vendor,
        'non-affiliate': !vendor,
        stacked: endAdornment && stackEndAdornment,
      })}
    >
      {vendor ? (
        <Link
          as="transparent"
          href={href}
          target="_blank"
          className={cx('link-preview', {[vendor]: vendor, compact})}
          data-qa-id="affiliate-link"
          data-qa-value={href}
          disabled={disabled}
        >
          {image && (
            <img
              src={image}
              className={cx('link-preview__image', {[imgClassName]: !!imgClassName})}
              alt={title}
            />
          )}
          <div className="link-preview__details">
            <div className="link-preview__title-wrapper">
              <Typography variant={titleVariant} color={titleColor} className="link-preview__title">
                {truncateTitle ? `${title.slice(0, MAX_TITLE_LENGTH_TO_SHOW)}...` : title}
              </Typography>
              {DisclaimerPopover}
            </div>
            {SubtitleView}
          </div>
        </Link>
      ) : (
        <Link
          href={href}
          variant="small"
          target="_blank"
          className="link-preview__unknown-owner"
          data-qa-id="affiliate-link"
        >
          <LinkIcon ratio={0.75} color={tanzanite} />
          {toTitleCase(displayText)}
        </Link>
      )}
      {endAdornment && (
        <div
          className={cx('link-preview__end-adornment', {
            'link-preview__end-adornment__stacked': endAdornment && stackEndAdornment,
          })}
        >
          {endAdornment}
        </div>
      )}
    </div>
  );
};

LinkPreview.propTypes = {
  href: PropTypes.string,
  displayText: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  titleVariant: PropTypes.string,
  titleColor: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleVariant: PropTypes.string,
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  vendor: PropTypes.string,
  disabled: PropTypes.bool,
  identifiedProduct: PropTypes.bool,
  compact: PropTypes.bool,
  DisclaimerPopover: PropTypes.node,
  endAdornment: PropTypes.node,
  stackEndAdornment: PropTypes.bool,
};
