import {useContext} from 'react';
import {useSelector} from 'react-redux';
import {useIsLargeAndUp} from '~/common/hooks/useMatchQuery';
import {ResponsivePanel} from '~library/organisms/ResponsivePanel';
import {FLOW_STATES} from '~/SignUpSheets/constants';
import {Menu} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/SlotToolBar/mobile/Menu';
import {MenuHeader} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/SlotToolBar/mobile/MenuHeader';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetSettings} from './SheetSettings';
import {OrganizerTools} from './OrganizerTools';

export const SheetOptionsMenu = ({title}) => {
  const isLargeAndUp = useIsLargeAndUp();
  const {flow} = useContext(SignUpSheetContext);
  const sheetId = useSelector(selectSheetId);
  const {optionsOpen, closeOptions} = useSheetOptions();

  const isCreateOrEdit = [FLOW_STATES.CREATE, FLOW_STATES.EDIT].includes(flow);

  let content = null;
  if (isCreateOrEdit) {
    content = <SheetSettings />;
  } else if (sheetId && flow === FLOW_STATES.VIEW) {
    content = <OrganizerTools />;
  }

  if (isLargeAndUp) {
    return (
      <ResponsivePanel
        isOpen={optionsOpen}
        title={title}
        modalId="signup-sheet-options"
        closeQaId="close-signup-sheet-options"
        showCover
        onClose={closeOptions}
      >
        {content}
      </ResponsivePanel>
    );
  }

  return (
    <>
      {optionsOpen && (
        <button
          className="unstyled-button mobile-menu__overlay"
          onClick={closeOptions}
          type="button"
          aria-label={`Close ${title}`}
          data-qa-id="close-signup-sheet-options"
        />
      )}
      <Menu
        header={
          <MenuHeader
            color="charcoal"
            variant="paragraph1"
            semibold
            toggleMenu={closeOptions}
            title={title}
            uppercase={false}
          />
        }
        items={content}
        menuVisible={optionsOpen}
        toggleMenu={closeOptions}
      />
    </>
  );
};
