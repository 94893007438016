import {useCallback, useState, useMemo} from 'react';
import {Typography} from '~library/atoms/Typography';
import {Button} from '~library/atoms/Button';
import {pluralize} from '~/common/utils';
import './RecipientCount.sass';

export const RecipientCount = ({selectedUserIds, volunteersMap}) => {
  const [showNames, setShowNames] = useState(false);

  const handleShowNames = useCallback(() => {
    setShowNames((prev) => !prev);
    if (!showNames) {
      setTimeout(() => {
        const container = document.querySelector('.message-volunteers');
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 0);
    }
  }, [showNames]);

  const recipientList = useMemo(
    () =>
      Array.from(selectedUserIds)
        .sort((a, b) => {
          const nameA = volunteersMap.get(a)?.name || '';
          const nameB = volunteersMap.get(b)?.name || '';
          return nameA.localeCompare(nameB);
        })
        .map((userId) => (
          <li key={userId}>
            <Typography variant="paragraph2" color="shadow">
              {volunteersMap.get(userId)?.name}
            </Typography>
          </li>
        )),
    [selectedUserIds, volunteersMap]
  );

  return (
    <div className="message-volunteers__recipients-count">
      <div className="message-volunteers__recipients-count__upper-row">
        <Typography variant="paragraph3" color="shadow" semibold>
          Sending to: {pluralize('recipient', selectedUserIds?.size || 0)}
        </Typography>
        <Button variant="link" onClick={handleShowNames}>
          {showNames ? 'Hide names' : 'Show names'}
        </Button>
      </div>
      {selectedUserIds?.size > 0 && showNames && (
        <div className="message-volunteers__recipients-count__lower-row">
          <ol>{recipientList}</ol>
        </div>
      )}
    </div>
  );
};
