import {useCallback, useMemo, useRef, useState} from 'react';
import {v4} from 'uuid';
import {Typography} from '~library/atoms/Typography';
import {FilledSlot} from './FilledSlot';
import {Accordion} from '~library/molecules/Accordion';
import {ChevronIcon} from '~library/svg/ChevronIcon';

import './FilledSlots.sass';

export const FilledSlots = ({titleElement, slots = {}, signedInId, newUserId}) => {
  const filledSlotsRef = useRef(null);
  const filledSlotsIdRef = useRef(v4());
  const [expanded, setExpanded] = useState(true);

  const isMatchingUserChecker = useCallback(
    (slot) => (signedInId ? slot.user_id === signedInId : slot.user_id === newUserId),
    [signedInId, newUserId]
  );

  const sortedSlots = useMemo(() => {
    const slotsToSorted = Object.values(slots);
    slotsToSorted.sort((a, b) => {
      const aIsMatchingUser = isMatchingUserChecker(a);
      const bIsMatchingUser = isMatchingUserChecker(b);
      if (aIsMatchingUser && !bIsMatchingUser) return -1;
      if (bIsMatchingUser && !aIsMatchingUser) return 1;
      return 0;
    });
    return slotsToSorted;
  }, [slots, isMatchingUserChecker]);

  const sortedSlotsGroupedByUser = useMemo(
    () =>
      sortedSlots.reduce((acc, curr) => {
        const currentUserId = curr.user_id;
        return {
          ...acc,
          [currentUserId]: {
            name: curr.name,
            email: curr.email,
            phone: curr.phone,
            signupsCount: (acc[currentUserId]?.signupsCount || 0) + 1,
            comment: curr.comment,
            isMatchingUser: isMatchingUserChecker(curr),
            signUpOptionId: curr?.signup_option,
            slotId: curr?.slot_id,
          },
        };
      }, {}),
    [sortedSlots, isMatchingUserChecker]
  );

  if (slots?.length === 0) {
    return null;
  }

  return (
    <Accordion
      uniqueAccordionBodyWrapperId={filledSlotsIdRef.current}
      className="filled-slots__container"
      label={
        titleElement ?? (
          <Typography variant="label2" size="smaller" className="filled-slots__title">
            Confirmed signups
          </Typography>
        )
      }
      labelVariant="label2"
      labelClassName="filled-slots__title"
      labelColor="charcoal"
      chevron={<ChevronIcon direction={expanded ? 'up' : 'down'} ratio={0.75} />}
      onClose={() => setExpanded(false)}
      onOpen={() => setExpanded(true)}
      arrowPlacement="right"
      isOpen={expanded}
      ref={filledSlotsRef}
    >
      {Object.entries(sortedSlotsGroupedByUser).map(
        ([
          userId,
          {name, comment, email, phone, isMatchingUser, signupsCount, signUpOptionId, slotId},
        ]) => (
          <FilledSlot
            key={userId}
            name={name}
            comment={comment}
            email={email}
            phone={phone}
            matchingUser={isMatchingUser}
            signupsCount={signupsCount}
            signUpOptionId={signUpOptionId}
            slotId={slotId}
            userId={userId}
          />
        )
      )}
    </Accordion>
  );
};
