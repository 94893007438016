import {useCallback, useState} from 'react';
import {Button} from '~library/atoms/Button';
import {ChevronIcon} from '~library/svg/ChevronIcon';
import {AdvancedOptionsModal} from '~/SignUpSheets/Create/routes/Customize/components/AdvancedOptions/modal/AdvancedOptionsModal';
import {OptionGroup} from '~/SignUpSheets/components/Header/components/SheetOptions/components/OptionGroup/OptionGroup';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetOption} from '~/SignUpSheets/components/Header/components/SheetOptions/components/SheetOption/SheetOption';

export const AdvancedOptions = () => {
  const {closeOptions} = useSheetOptions();
  const [showAdvancedOptionsModal, setShowAdvancedOptionsModal] = useState(false);

  const openAdvancedOptions = useCallback(() => {
    setShowAdvancedOptionsModal(true);
  }, []);

  const closeAdvancedOptions = useCallback(() => {
    setShowAdvancedOptionsModal(false);
    closeOptions();
  }, [closeOptions]);

  return (
    <>
      <OptionGroup title="Advanced Slot Options">
        <SheetOption
          title="Create multiple days and times"
          description="Perfect for creating signups over many days"
        >
          <Button
            variant="secondary"
            size="large"
            data-qa-id="open-advanced-options"
            onClick={openAdvancedOptions}
          >
            Create slots
            <ChevronIcon ratio={0.5} direction="right" />
          </Button>
        </SheetOption>
      </OptionGroup>

      {showAdvancedOptionsModal && <AdvancedOptionsModal onClose={closeAdvancedOptions} />}
    </>
  );
};
