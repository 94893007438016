import {Typography} from '~library/atoms/Typography';
import {PrintableSlot} from '~/SignUpSheets/components/PrintableView/PrintableSlot/PrintableSlot';

import './PrintableSignups.sass';

export const PrintableSignups = ({date, title, slots = [], isDatetimeType = false}) => (
  <table className="printable-signups">
    {isDatetimeType && (
      <thead className="printable-signups__header-wrapper">
        <tr>
          <th className="printable-slot__no-padding">
            <div className="printable-signups__header">
              {date && (
                <Typography
                  variant="paragraph3"
                  semibold
                  className="printable-signups__header__date"
                >
                  {date}
                </Typography>
              )}
              {title && (
                <Typography variant="paragraph3" semibold>
                  {title}
                </Typography>
              )}
            </div>
          </th>
        </tr>
      </thead>
    )}
    <tbody>
      {slots.map((slot) => (
        <tr key={slot.slot_id ?? slot.uuid} className="printable-signups__full-width-row">
          <td>
            <PrintableSlot
              date={date}
              title={title}
              isDatetimeType={isDatetimeType}
              key={slot.slot_id ?? slot.uuid}
              slot={slot}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
