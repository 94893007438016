import {useMatchQuery} from '~library/hooks/useMatchQuery';
import {Typography} from '~library/atoms/Typography';
import {Button} from '~library/atoms/Button';
import {PaintIcon} from '~library/svg/PaintIcon';
import {ChevronIcon} from '~library/svg/ChevronIcon';
import {Categories} from './Categories';
import {mediumAndUpQuery} from '~libSass/base/_exports.sass';

export const OverlayContent = ({handleClose}) => {
  const {matches: isDesktop} = useMatchQuery(`(${mediumAndUpQuery})`);

  return (
    <>
      {!isDesktop && (
        <div className="themer-bottom-sheet__heading">
          <PaintIcon />
          <Typography
            variant="paragraph2"
            semibold
            className="themer-botto-sheet__heading-copy"
            color="charcoal"
          >
            Change theme
          </Typography>
          <Button
            variant="transparent"
            color="pebble"
            onClick={handleClose}
            data-qa-id="theme-close-button"
            className="themer-bottom-sheet__heading__close"
          >
            <ChevronIcon direction="up" />
          </Button>
        </div>
      )}
      <div className="themer-overlay__content">
        <Categories />
      </div>
    </>
  );
};
