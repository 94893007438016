import cx from 'classnames';
import {useSelector} from 'react-redux';
import {Link} from '~library/atoms/Link';
import {Typography} from '~library/atoms/Typography';
import {AffiliateIcons} from '~/SignUpSheets/utils/affiliate';
import {LinkPreview} from '~/SignUpSheets/components/LinkPreview/LinkPreview';
import {selectIsOrganizer, selectOrganizerName} from '~/SignUpSheets/reducers/sheet/selectors';
import {toTitleCase} from '~/common/fabric/CreateDesign/components/CardEditor/actions/TextFormat/components/Casing/Casing';

import './BuyFromSuggestion.sass';

export const BuyFromSuggestion = ({product = {}, className}) => {
  const isOrganizer = useSelector(selectIsOrganizer);
  const organizerName = useSelector(selectOrganizerName);

  if (!product.affiliate_url) {
    return null;
  }

  return (
    <div className={cx('signup__buy-from-suggestion', className)}>
      {product.affiliate_url &&
        (isOrganizer || !product.not_safe) &&
        (product.vendor_name ? (
          <img
            src={product.images?.primary?.medium?.url ?? AffiliateIcons.get(product.vendor_name)}
            alt={product.title ?? product.vendor_name}
            height={54}
            width={54}
          />
        ) : (
          <LinkPreview href={product.affiliate_url} displayText={product.display_text} />
        ))}

      <div className="signup__buy-from-suggestion__info">
        <Typography variant="paragraph4" size="small" color="pebble">
          {organizerName} has a suggested purchase from {toTitleCase(product?.vendor_name ?? '')}
        </Typography>

        {product.vendor_name && (
          <div className="signup__buy-from-suggestion__lower">
            <Link
              href={product.affiliate_url}
              target="_blank"
              as="primary"
              variant="small"
              data-qa-id="purchase-now"
            >
              Purchase now
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
