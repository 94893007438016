import cx from 'classnames';
import {shallowEqual, useSelector} from 'react-redux';
import {
  selectIsPreviewMode,
  selectOrganizerName,
  selectWishlistName,
  selectWishlistOption,
  selectWishlists,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {
  addAffiliateTagsToWishlist,
  getBrandNameAndIcon,
  normalizeUrl,
} from '~/SignUpSheets/utils/misc';
import {Link} from '~library/atoms/Link';
import {Typography} from '~library/atoms/Typography';
import {VendorDisc} from '~library/molecules/VendorDisc';
import {selectBlueprintId} from '~/SignUpSheets/reducers/blueprint/selectors';

import './VolunteerWishlist.sass';

export const VolunteerWishlist = () => {
  const wishlistOption = useSelector(selectWishlistOption);
  const wishlists = useSelector(selectWishlists, shallowEqual);
  const blueprintId = useSelector(selectBlueprintId);
  const wishlistName = useSelector(selectWishlistName);
  const organizerName = useSelector(selectOrganizerName);
  const isPreviewMode = useSelector(selectIsPreviewMode);

  const title = wishlistName || `Contribute to ${organizerName}'s wish list`;

  const showEviteVendors = wishlistOption === 'evite-wishlist';

  return (
    <div className={cx('signup-sheet__volunteer-wishlist-section', {preview: isPreviewMode})}>
      <div>
        <Typography variant="header3">
          {showEviteVendors ? 'Need something for your signup?' : title}
        </Typography>
        {showEviteVendors && (
          <Typography className="wishlist-section__option__description" variant="paragraph3">
            Get what you need with help from our partners.
          </Typography>
        )}
      </div>
      {showEviteVendors ? (
        <VendorDisc
          imgSrc="https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Amazon@2x.png"
          href={addAffiliateTagsToWishlist('https://www.amazon.com/', blueprintId, false)}
          displayText="Shop supplies"
          vendorName="Amazon"
          textColor="tanzanite"
          semibold
        />
      ) : (
        <div className="volunteer-wishlist-section__vendors-list">
          {wishlists.map((wishlistUrl) => {
            const {brandName, iconUrl} = getBrandNameAndIcon(wishlistUrl);

            return (
              <Link
                href={addAffiliateTagsToWishlist(normalizeUrl(wishlistUrl || '#'), blueprintId)}
                target="_blank"
                className="volunteer-wishlist-section__vendors-item"
                key={wishlistUrl}
                data-qa-id="volunteer-wishlist-item"
              >
                <img
                  src={iconUrl}
                  alt={brandName}
                  className={`volunteer-wishlist-section__vendors-item__image ${
                    !brandName ? 'volunteer-wishlist-section__vendors-item__image-default' : ''
                  }`}
                />
                <Typography
                  variant="link"
                  size="large"
                  className="volunteer-wishlist-section__vendors-item__title"
                >
                  {`View the ${brandName || ''} Wishlist`}
                </Typography>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
