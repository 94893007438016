import {Divider} from '~library/atoms/Divider';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {useViewOnlyMode} from '~/SignUpSheets/components/Details/ViewOnlyModeProvider';

import './DetailsDivider.sass';

export const DetailsDivider = ({props}) => {
  const isDesktop = useIsLargishAndUp();
  const viewOnlyMode = useViewOnlyMode();

  if (!viewOnlyMode || isDesktop) return null;

  return (
    <div className="details-divider__wrapper">
      <Divider {...props} />
    </div>
  );
};
