import {formatDateShort} from '~/SignUpSheets/utils/misc';
import {Divider} from '~library/atoms/Divider';
import {SignUpItem} from './SignUpItem';
import {Header} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/Header/Header';

import './SignUpDay.sass';

export const SignUpDay = ({date, title, description, signedUp, slots}) => {
  const renderedDate = formatDateShort(date);

  return (
    <div className="confirm-page__signup-day-group">
      <Header
        title={renderedDate || title}
        subtitle={renderedDate ? title : ''}
        description={description}
      />
      {slots.map((slot) => (
        <SignUpItem
          key={slot?.slot_id}
          title={slot?.title}
          count={slot?.count}
          product={slot?.product}
          comment={slot?.comment}
          description={slot?.description}
          startTime={slot?.start_time}
          endTime={slot?.end_time}
          calendarLinks={slot.calendarLinks ?? signedUp.calendarLinks ?? {}}
        />
      ))}
      <Divider />
    </div>
  );
};
