import {useCallback, useMemo} from 'react';
import {Typography} from '~library/atoms/Typography';
import {Footer} from '~/SignUpSheets/components/Header/components/SheetOptions/MessageVolunteers/components/Footer';
import {SlotGroup} from './components/SlotGroup';

import './SelectVolunteers.sass';

export const SelectVolunteers = ({onNext, selectedUserIds, setSelectedUserIds, groupedData}) => {
  const handleGroupSelect = useCallback(
    (volunteers) => {
      setSelectedUserIds((prev) => {
        const newSet = new Set(prev);
        const groupVolunteers = volunteers.map((v) => v.userId);
        const allSelected = groupVolunteers.every((userId) => newSet.has(userId));

        if (allSelected) {
          groupVolunteers.forEach((userId) => {
            newSet.delete(userId);
          });
        } else {
          groupVolunteers.forEach((userId) => {
            newSet.add(userId);
          });
        }

        return newSet;
      });
    },
    [setSelectedUserIds]
  );

  const handleVolunteerSelect = useCallback(
    (volunteer) => {
      setSelectedUserIds((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(volunteer.userId)) {
          newSet.delete(volunteer.userId);
        } else {
          newSet.add(volunteer.userId);
        }
        return newSet;
      });
    },
    [setSelectedUserIds]
  );

  const renderGroups = useMemo(
    () =>
      groupedData.map((group, index) => {
        if (group.subGroups) {
          return (
            <div key={group.id} className="message-volunteers__date-section">
              <Typography variant="paragraph3" semibold className="message-volunteers__date-header">
                {group.title}
              </Typography>
              {group.subGroups.map((subGroup, subIndex) => (
                <SlotGroup
                  key={subGroup.id}
                  group={subGroup}
                  index={index + subIndex}
                  selectedUserIds={selectedUserIds}
                  onGroupSelect={handleGroupSelect}
                  onVolunteerSelect={handleVolunteerSelect}
                />
              ))}
            </div>
          );
        }

        return (
          <SlotGroup
            key={group.id}
            group={group}
            index={index}
            selectedUserIds={selectedUserIds}
            onGroupSelect={handleGroupSelect}
            onVolunteerSelect={handleVolunteerSelect}
          />
        );
      }),
    [groupedData, handleGroupSelect, handleVolunteerSelect, selectedUserIds]
  );

  return (
    <div className="message-volunteers__step">
      <div className="message-volunteers__content">
        <Typography
          variant="paragraph2"
          semibold
          color="shadow"
          className="message-volunteers__label"
        >
          Select slot group:
        </Typography>
        <div className="message-volunteers__groups">{renderGroups}</div>
      </div>
      <Footer onNext={onNext} showBack={false} nextDisabled={selectedUserIds.size === 0} />
    </div>
  );
};
