import {ResponsiveModal} from '~library/organisms/ResponsiveModal';

export const ConfirmDeleteSlotsModal = ({onSubmit, onClose}) => (
  <ResponsiveModal
    isOpen
    title="Are you sure you want to delete?"
    primaryText="Yes, delete"
    primaryAction={onSubmit}
    secondaryText="No, save my slots"
    secondaryAction={onClose}
    onClose={onClose}
    body="Deleting will result in all slots & data being deleted."
  />
);
