import cx from 'classnames';
import PropTypes from 'prop-types';
import {TextEditor} from '~library/organisms/TextEditor';
import {Typography} from '~library/atoms/Typography';
import {ValidationError} from '~/SignUpSheets/components/ValidationError/ValidationError';
import './RichTextField.sass';

export const RichTextField = ({
  label,
  value,
  onChange,
  onBlur,
  placeholder,
  maxLength,
  error,
  id = 'description',
  dataQaId,
  innerQuillClassName,
  disabled = false,
}) => {
  const toolbarId = `${id}-toolbar`;

  return (
    <div>
      <TextEditor
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disabled}
        id={id}
        toolbar={
          <div className="rich-text-field-toolbar">
            {label && (
              <Typography variant="paragraph2" color="pebble">
                {label}
              </Typography>
            )}
            <div className="rich-text-field-toolbar__options" id={toolbarId}>
              <button className="ql-bold" type="button" aria-label="bold" />
              <button className="ql-italic" type="button" aria-label="italic" />
              <span className="ql-formats">
                <button className="ql-list" value="bullet" type="button" aria-label="bullet list" />
                <button
                  className="ql-list"
                  value="ordered"
                  type="button"
                  aria-label="ordered list"
                />
              </span>
              <span className="ql-formats">
                <button className="ql-link" type="button" aria-label="link" />
              </span>
            </div>
          </div>
        }
        toolbarId={toolbarId}
        data-qa-id={dataQaId}
        placeholder={placeholder}
        containerClassName={cx('rich-text-field__container', {
          error,
        })}
        innerQuillClassName={cx(
          'rich-text-field__container__quill',
          'paragraph2',
          innerQuillClassName
        )}
        toolbarAlign="left"
      />
      <ValidationError error={error} dataQaIdStub={dataQaId} />
    </div>
  );
};

RichTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  dataQaId: PropTypes.string.isRequired,
  innerQuillClassName: PropTypes.string,
  disabled: PropTypes.bool,
};
