import moment from 'moment-timezone';

export const createVolunteerObject = (signup) => ({
  id: signup.id,
  name: signup.name,
  userId: signup.user_id,
  email: signup.email,
});

const formatDate = (dateString) => moment(dateString).format('ddd, MMM D, YYYY');

export const getUniqueVolunteers = (signups) => {
  const uniqueVolunteers = new Map();
  const volunteers = [];

  signups.forEach((signup) => {
    const volunteer = createVolunteerObject(signup);
    if (!uniqueVolunteers.has(volunteer.userId)) {
      uniqueVolunteers.set(volunteer.userId, volunteer);
      volunteers.push(volunteer);
    }
  });

  return volunteers;
};

const processBP4Data = (signupOptions, signups) => {
  const dateGroups = new Map();

  signupOptions.forEach((option) => {
    const {date, slots} = option;
    const formattedDate = formatDate(date);

    if (!dateGroups.has(formattedDate)) {
      dateGroups.set(formattedDate, []);
    }

    slots.forEach((slot) => {
      const groupId = `${date}-${slot.slot_id}`;
      const slotSignups = signups.filter((signup) => signup.slot_id === slot.slot_id);
      const groupVolunteers = getUniqueVolunteers(slotSignups);

      if (groupVolunteers.length > 0) {
        let slotTitle;
        if (slot.title && slot.title.trim() !== '') {
          slotTitle = slot.title;
        } else if (slot.start_time && slot.end_time) {
          slotTitle = `${slot.start_time} - ${slot.end_time}`;
        } else {
          slotTitle = 'Untitled Slot';
        }

        dateGroups.get(formattedDate).push({
          id: groupId,
          title: `${formattedDate} | ${slotTitle}`,
          volunteers: groupVolunteers,
          date: formattedDate,
        });
      }
    });
  });

  const processedGroups = [];
  dateGroups.forEach((groups, date) => {
    processedGroups.push({
      id: `date-${date}`,
      title: date,
      subGroups: groups,
    });
  });

  return processedGroups;
};

const processBP1WithTimeslots = (groups, signupOptions, signups) => {
  const timeGroups = new Map();

  Object.entries(groups).forEach(([groupId, group]) => {
    const timeSlot = `${group.start_time} - ${group.end_time}`;

    if (!timeGroups.has(timeSlot)) {
      timeGroups.set(timeSlot, []);
    }

    const groupSignupOptions = signupOptions.filter((option) => option.group === groupId);
    const groupSignups = signups.filter((signup) =>
      groupSignupOptions.some((option) => option.uuid === signup.signup_option)
    );

    groupSignupOptions.forEach((option) => {
      const optionSignups = groupSignups.filter((signup) => signup.signup_option === option.uuid);
      const groupVolunteers = getUniqueVolunteers(optionSignups);

      if (groupVolunteers.length > 0) {
        timeGroups.get(timeSlot).push({
          id: option.uuid,
          title: `${timeSlot} | ${option.item || ''}`,
          volunteers: groupVolunteers,
        });
      }
    });
  });

  const processedGroups = [];
  timeGroups.forEach((timeGroup, timeSlot) => {
    processedGroups.push({
      id: `time-${timeSlot}`,
      title: timeSlot,
      subGroups: timeGroup,
    });
  });

  return processedGroups;
};

const processBP1WithoutTimeslots = (signupOptions, signups) => {
  const processedGroups = [];

  signupOptions.forEach((option) => {
    const optionSignups = signups.filter((signup) => signup.signup_option === option.uuid);
    const groupVolunteers = getUniqueVolunteers(optionSignups);

    if (groupVolunteers.length > 0) {
      processedGroups.push({
        id: option.uuid,
        title: option.item || '',
        volunteers: groupVolunteers,
      });
    }
  });

  return processedGroups;
};

const sortGroups = (processedGroups, blueprintId, groups) => {
  processedGroups.sort((a, b) => {
    if (blueprintId === 'standalone-event') {
      if (!Object.keys(groups).length) {
        return a.title.localeCompare(b.title);
      }
      return a.title.localeCompare(b.title);
    }
    return (
      moment(a.title, 'ddd, MMM D, YYYY').valueOf() - moment(b.title, 'ddd, MMM D, YYYY').valueOf()
    );
  });

  processedGroups.forEach((group) => {
    if (group.subGroups) {
      group.subGroups.sort((a, b) => a.title.localeCompare(b.title));

      group.subGroups.forEach((subGroup) => {
        subGroup.volunteers.sort((a, b) => a.name.localeCompare(b.name));
      });
    } else if (group.volunteers) {
      group.volunteers.sort((a, b) => a.name.localeCompare(b.name));
    }
  });

  return processedGroups;
};

const hasVolunteers = (group) => {
  if (group?.volunteers?.length > 0) {
    return true;
  }

  if (group?.subGroups) {
    return group.subGroups.some((subGroup) => subGroup.volunteers.length > 0);
  }

  return false;
};

export const processData = (blueprintId, signupOptions, signups, groups) => {
  let processedGroups = [];

  switch (blueprintId) {
    case 'days-times-descriptions':
      processedGroups = processBP4Data(signupOptions, signups);
      break;
    case 'standalone-event':
      if (Object.keys(groups).length) {
        processedGroups = processBP1WithTimeslots(groups, signupOptions, signups);
      } else {
        processedGroups = processBP1WithoutTimeslots(signupOptions, signups);
      }
      break;
    default:
      processedGroups = [];
      break;
  }

  processedGroups = sortGroups(processedGroups, blueprintId, groups);
  return processedGroups.filter(hasVolunteers);
};
