import cx from 'classnames';
import {LinkIcon} from '~library/svg/LinkIcon';
import {toTitleCase} from '~/SignUpSheets/utils/misc';
import {Link} from '~library/atoms/Link';

import {tanzanite} from '~libSass/base/_exports.sass';
import './UnknownUrl.sass';

export const UnknownUrl = ({url, displayText, className}) => (
  <Link
    href={url}
    variant="small"
    target="_blank"
    className={cx('unknown-url', className)}
    data-qa-id="affiliate-link"
  >
    <LinkIcon ratio={0.75} color={tanzanite} />
    {toTitleCase(displayText)}
  </Link>
);
