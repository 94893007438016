import cx from 'classnames';
import {useSelector, useStore} from 'react-redux';
import {useCallback, useRef, useState} from 'react';
import {Popover} from 'react-tiny-popover';
import urls from 'urls';
import {isMobile} from '~library/utils/utils';
import {EviteModal} from '~/modal';
import {CalendarIcon} from '~library/svg/CalendarIcon';
import {pebble, tanzanite} from '~library/sass/base/_exports.sass';
import {Typography} from '~library/atoms/Typography';
import {Button} from '~library/atoms/Button';
import {GoogleIcon} from '~library/svg/GoogleIcon';
import {AppleIcon} from '~library/svg/AppleIcon';
import {OutlookIcon} from '~library/svg/OutlookIcon';
import {Link} from '~library/atoms/Link';

import {getLoggedUserId} from '~/common/fabric/User/reducers/user';
import {useRequestLogin} from '~/common/fabric/Card/components/LoginModal/hooks/useRequestLogin';
import {updateUser} from '~library/utils/auth/utils/';

export const AddToCalendarSus = ({
  isButton,
  preview: isPreview,
  title = 'Add to calendar',
  text = '',
  ratio = 0.75,
  className = '',
  googleCalUrl = window.calendar_urls?.google_cal_url ?? '',
  iCalUrl,
  outlookUrl,
  type = 'event',
  buttonSize = 'large',
  requiresLogin = false,
  afterLogin,
  'data-type': dataType = 'calendar',
  'data-qa-id': dataQaId,
  'data-source': dataSource = '',
  'data-action': dataAction = 'download',
}) => {
  const ref = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const textToShow = text || title;
  const isLoggedIn = useSelector(getLoggedUserId);
  const {requestLogin} = useRequestLogin();
  const store = useStore();

  const downloadCalendarEvent = () => {
    window.location.href = `${window.location.origin}/event/${window.event_id}/export/ical/?gid=${window.guest_id}`;
  };

  const createAddToCalendarModal = () => {
    const isMobile = isMobile();

    return new EviteModal({
      width: '640px',
      url: urls.get('modal_add_to_calendar', {
        event_id: window.event_id,
      }),
      url_parameters: {
        guest_id: window.guest_id,
        is_mobile: isMobile,
      },
    });
  };

  const handleSelectAppleOrOutlookCalendar = (event) => {
    event.preventDefault();

    const isMobile = isMobile();
    if (isMobile) {
      downloadCalendarEvent();
    } else {
      const addToCalendarModal = createAddToCalendarModal();
      addToCalendarModal.show();
    }

    return false;
  };

  const handleClick = useCallback(
    async (event) => {
      event.preventDefault();
      if (requiresLogin && !isLoggedIn) {
        const loggedIn = await requestLogin();
        if (!loggedIn) {
          return;
        }
        await updateUser(store);
        if (afterLogin) {
          await afterLogin();
        }
      }
      setMenuOpen((prevState) => !prevState);
    },
    [requiresLogin, isLoggedIn, requestLogin, updateUser, store, afterLogin, setMenuOpen]
  );

  const renderGoogleCalendarButton = useCallback(
    () =>
      googleCalUrl ? (
        <Link
          as="transparent"
          variant="small"
          className="add-to-calendar__calendar-option"
          href={googleCalUrl}
          target="_blank"
          data-qa-id="add-to-cal__google"
        >
          <GoogleIcon ratio={0.75} />
          Add to Google calendar
        </Link>
      ) : null,
    [googleCalUrl]
  );

  const renderAppleCalendarButton = useCallback(() => {
    if (iCalUrl) {
      return (
        <Link
          as="transparent"
          variant="small"
          className="add-to-calendar__calendar-option"
          href={iCalUrl}
          target="_blank"
          data-qa-id="add-to-cal__apple"
        >
          <AppleIcon ratio={0.75} />
          Add to Apple calendar
        </Link>
      );
    }
    if (type === 'event') {
      return (
        <Button
          variant="transparent"
          className="add-to-calendar__calendar-option"
          onClick={handleSelectAppleOrOutlookCalendar}
          data-qa-id="add-to-cal__apple"
        >
          <AppleIcon ratio={0.75} />
          Add to Apple calendar
        </Button>
      );
    }
    return null;
  }, [iCalUrl, type, handleSelectAppleOrOutlookCalendar]);

  const renderOutlookCalendarButton = useCallback(() => {
    if (outlookUrl) {
      return (
        <Link
          as="transparent"
          variant="small"
          className="add-to-calendar__calendar-option"
          href={outlookUrl}
          target="_blank"
          data-qa-id="add-to-cal__outlook"
        >
          <OutlookIcon ratio={0.75} />
          Add to Outlook calendar
        </Link>
      );
    }
    if (type === 'event') {
      return (
        <Button
          variant="transparent"
          className="add-to-calendar__calendar-option"
          onClick={handleSelectAppleOrOutlookCalendar}
          data-qa-id="add-to-cal__outlook"
        >
          <OutlookIcon ratio={0.75} />
          Add to Outlook calendar
        </Button>
      );
    }
    return null;
  }, [outlookUrl, type, handleSelectAppleOrOutlookCalendar]);

  const calendarLinksAvailable = type === 'event' || googleCalUrl || iCalUrl || outlookUrl;

  // Not clickable if its preview mode.
  return calendarLinksAvailable ? (
    <Popover
      isOpen={menuOpen}
      positions={['bottom', 'top']}
      padding={8}
      onClickOutside={handleClick}
      content={
        <div id="calendar-options-menu" className="add-to-calendar__calendar-options-menu">
          {renderGoogleCalendarButton()}
          {renderAppleCalendarButton()}
          {renderOutlookCalendarButton()}
        </div>
      }
    >
      <button
        id="add-to-calendar"
        data-qa-id={dataQaId ?? `add-to-calendar`}
        className={cx('unstyled-button', 'link', buttonSize, className, 'add-to-calendar-btn', {
          disabled: isPreview,
        })}
        title={title}
        data-type={dataType}
        data-source={dataSource}
        data-action={dataAction}
        onClick={handleClick}
        disabled={isPreview}
        ref={ref}
        type="button"
      >
        {isButton ? (
          `Add to calendar`
        ) : (
          <>
            <CalendarIcon ratio={ratio} color={isPreview ? pebble : tanzanite} />
            <Typography
              classNames="add-to-calendar-btn-text"
              color={isPreview ? 'pebble' : 'tanzanite'}
              variant="paragraph2"
            >
              {textToShow}
            </Typography>
          </>
        )}
      </button>
    </Popover>
  ) : null;
};
