import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {toastAlert} from '~library/atoms/Toast';
import {Footer} from '~/SignUpSheets/components/Header/components/SheetOptions/MessageVolunteers/components/Footer';
import {RecipientCount} from '~/SignUpSheets/components/Header/components/SheetOptions/MessageVolunteers/components/RecipientCount';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';
import {useSendMessageToVolunteersMutation} from '~/SignUpSheets/api/sheet';
import {FakeMail} from './components/FakeMail';

export const PreviewMessage = ({onBack, onClose, message, selectedUserIds, volunteersMap}) => {
  const [sendMessageToVolunteers, {isLoading}] = useSendMessageToVolunteersMutation();
  const sheetId = useSelector(selectSheetId);

  const onSend = useCallback(async () => {
    try {
      await sendMessageToVolunteers({
        id: sheetId,
        volunteers: Array.from(selectedUserIds),
        message,
      }).unwrap();

      toastAlert('Message sent!', {
        id: 'message-sent-success',
        toastId: 'message-sent-success',
        type: 'success',
        position: 'top-right',
        autoClose: 3000,
      });
      onClose();
    } catch (error) {
      const detail = error.data?.error?.data?.detail?.[0];
      const errorMessage = detail
        ? `Missing required field: ${detail.loc[detail.loc.length - 1]}`
        : error.message || 'Failed to send message';

      toastAlert(errorMessage, {
        id: 'message-sent-error',
        toastId: 'message-sent-error',
        type: 'error',
        position: 'top-right',
        autoClose: 3000,
      });
    }
  }, [sheetId, selectedUserIds, message, sendMessageToVolunteers, onClose]);

  return (
    <div className="message-volunteers__step">
      <div className="message-volunteers__content">
        <FakeMail message={message} />
        <RecipientCount selectedUserIds={selectedUserIds} volunteersMap={volunteersMap} />
      </div>

      <Footer onBack={onBack} onNext={onSend} nextLabel="Send message" nextDisabled={isLoading} />
    </div>
  );
};
