import {useEffect, useMemo, useRef, useState} from 'react';
import cx from 'classnames';
import {createRoot} from 'react-dom/client';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {ModalProvider} from 'react-modal-hook';
import {useReactToPrint} from 'react-to-print';
import {TransitionGroup} from 'react-transition-group';
import {Route, Router, Switch, useLocation} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {history} from '~/SignUpSheets/history';
import {store} from '~/SignUpSheets/reducers/store';
import {selectBackground} from '~/SignUpSheets/reducers/themes/selectors';
import {InjectableMegaNav} from '~library/organisms/InjectableMegaNav';
import {useIsSmallAndUp} from '~library/hooks/useMatchQuery';
import {INIT_APP} from '~/common/fabric/constants';
import {getUserData} from '~library/utils/auth/utils';
import {InjectableFooter} from '~library/organisms/InjectableFooter';
import {LoginModalWrapper} from '~library/organisms/LoginModal/LoginModalWrapper';
import {hideLoginModal} from '~library/organisms/LoginModal/reducer';
import {userIsLoggedIn} from '~/common/fabric/User/reducers/user';
import {usePrevious} from '~/common/hooks/usePrevious';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {SET_BLUEPRINT} from '~/SignUpSheets/reducers/blueprint/constants';
import {useLazyGetBlueprintQuery} from '~/SignUpSheets/api/sheet';
import {selectBlueprintId} from '~/SignUpSheets/reducers/blueprint/selectors';
import {VolunteerConfirm} from './routes/VolunteerConfirm/VolunteerConfirm';
import {VolunteerView} from './routes/VolunteerView/VolunteerView';
import {Header} from '~/SignUpSheets/components/Header/Header';
import {useGetAllThemesQuery} from '~/SignUpSheets/api/signUpSheets';
import {SET_THEMES} from '~/SignUpSheets/reducers/themes/constants';
import {preloadROKT} from '~/common/utils/ROKT';
import {SignUpSheetContextParent} from '~/SignUpSheets/context/SignUpSheetContext';
import {selectIsOrganizer, selectSheetStatus} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectIsReady} from '~/SignUpSheets/reducers/selectors';
import {LoadingOverlay} from '~library/molecules/LoadingOverlay';
import {PrintableView} from '~/SignUpSheets/components/PrintableView/PrintableView';

import 'react-toastify/dist/ReactToastify.min.css';
import '~/SignUpSheets/app.sass';

store.dispatch({
  type: INIT_APP,
  payload: {
    user: getUserData(),
  },
});

let appInit = false;
const App = () => {
  const printableViewRef = useRef(null);
  const onPrint = useReactToPrint({contentRef: printableViewRef, preserveAfterPrint: true});
  const dispatch = useDispatch();
  const location = useLocation();
  const [showSiteNav, setShowSiteNav] = useState(false);
  const isOrganizer = useSelector(selectIsOrganizer);
  const path = location.pathname.match(/\/(?<path>[a-z]+$)/)?.groups.path || '';
  const isSuccess = path === 'success';
  const {getSavedSheet, isLazyFetchingSheet} = useSheetActions();
  const isLoggedIn = useSelector(userIsLoggedIn);
  const wasLoggedIn = usePrevious(isLoggedIn);
  const blueprintId = useSelector(selectBlueprintId);
  const status = useSelector(selectSheetStatus);
  const ready = useSelector(selectIsReady);
  const isDesktop = useIsSmallAndUp();
  const backgroundImage = useSelector((s) => selectBackground(s, {isDesktop}));
  const rootStyles = useMemo(() => ({backgroundImage}), [backgroundImage]);
  const {data: themes} = useGetAllThemesQuery();

  useEffect(() => {
    if (themes?.length > 0) {
      dispatch({type: SET_THEMES, payload: themes});
    }
  }, [themes]);

  const [getBlueprint] = useLazyGetBlueprintQuery();

  useEffect(() => {
    preloadROKT();
  }, []);

  useEffect(() => {
    if (!appInit) {
      appInit = true;
      if (window.blueprint) {
        dispatch({
          type: SET_BLUEPRINT,
          payload: window.blueprint,
        });
      }
      getSavedSheet();
    }
  }, [location, path]);

  useEffect(() => {
    dispatch(hideLoginModal());
  }, []);

  useEffect(() => {
    if (!blueprintId) return;
    getBlueprint(blueprintId)
      .then((res) => {
        dispatch({
          type: SET_BLUEPRINT,
          payload: res.data.data,
        });
      })
      .catch(evite.error);
  }, [dispatch, getBlueprint, blueprintId, path]);

  useEffect(() => {
    setShowSiteNav(!isOrganizer || path === 'success');
  }, [path, setShowSiteNav, isOrganizer]);

  useEffect(() => {
    if (wasLoggedIn === false && isLoggedIn === true) {
      getSavedSheet();
    }
  }, [getSavedSheet, wasLoggedIn, isLoggedIn]);

  return (
    <SignUpSheetContextParent isLazyFetchingSheet={isLazyFetchingSheet}>
      {showSiteNav ? <InjectableMegaNav /> : <Header handlePrint={onPrint} />}
      <div
        className={cx(path, 'signup-sheet__page-wrapper', {
          'signup-sheet__page-wrapper--only-bottom-padding': showSiteNav,
        })}
        style={isDesktop && !isSuccess ? rootStyles : {}}
        key={isLazyFetchingSheet}
      >
        {(status === 'canceled' || isDesktop) &&
          !isSuccess &&
          /\.(mp4|web)$/.test(backgroundImage) && (
            <video
              src={backgroundImage}
              className="signup-sheet__page-wrapper--video-bg"
              autoPlay
              loop
              muted
            />
          )}
        <Switch>
          <Route exact path="/:sheetId" component={VolunteerView} />
          <Route exact path="/:sheetId/success" component={VolunteerConfirm} />
        </Switch>
      </div>
      <LoadingOverlay show={!ready || isLazyFetchingSheet} loadingMessage="Loading sheet..." />
      {showSiteNav && <InjectableFooter />}
      <div style={{display: 'none'}}>
        <PrintableView contentRef={printableViewRef} />
      </div>
    </SignUpSheetContextParent>
  );
};

const ViewSignUpSheetAppWrapper = () => (
  <Provider store={store}>
    <ModalProvider rootComponent={TransitionGroup}>
      <Router history={history}>
        <LoginModalWrapper />
        <App />
      </Router>

      <ToastContainer
        position="bottom-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        icon={false}
      />
    </ModalProvider>
  </Provider>
);

const root = createRoot(document.getElementById('signup-sheet-root'));
root.render(<ViewSignUpSheetAppWrapper />);
