import cx from 'classnames';
import PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {selectErrors, selectSignupOptionGroupById} from '~/SignUpSheets/reducers/sheet/selectors';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {TimeGroupConfig} from './TimeGroupConfig';
import {ChevronIcon} from '~library/svg/ChevronIcon';
import {Button} from '~library/atoms/Button';

import './GroupConfig.sass';

const GROUP_TYPE_TO_CONFIG_MAP = Object.freeze({
  time: TimeGroupConfig,
  // Add more group types as needed
});

export const GroupConfig = ({
  groupId,
  disabled = false,
  isDesktop = false,
  expanded = false,
  onToggle,
  toolsElement = null,
  previewMode = false,
  expired = false,
}) => {
  const group = useSelector(selectSignupOptionGroupById(groupId), shallowEqual);
  const errors = useSelector(selectErrors, shallowEqual);
  const {updateGroup} = useUpdateSheet();

  const onChange = useCallback(
    (payload) => {
      if (!groupId) return;
      updateGroup(groupId, payload);
    },
    [groupId, updateGroup]
  );

  const ConfigElement = useMemo(() => {
    if (!(group.group_type in GROUP_TYPE_TO_CONFIG_MAP)) return null;
    const Element = GROUP_TYPE_TO_CONFIG_MAP[group.group_type];
    return (
      <Element
        group={{...group, id: groupId}}
        disabled={disabled}
        isDesktop={isDesktop}
        errors={errors}
        onChange={onChange}
        expired={expired}
      />
    );
  }, [group, groupId, disabled, isDesktop, errors, onChange, expired]);

  if (!group) return null;
  return (
    <div className={cx('group-config', {disabled})}>
      {disabled && !previewMode ? null : (
        <Button
          variant="transparent"
          onClick={() => onToggle?.(!expanded)}
          data-qa-id={`group-expand-collapse-${groupId}`}
          aria-label={`${expanded ? 'Collapse' : 'Expand'} group`}
          type="button"
          className="group-config__group-toggle"
        >
          <ChevronIcon direction={expanded ? 'up' : 'down'} ratio={0.75} />
        </Button>
      )}
      <div className="group-config__group-details">{ConfigElement}</div>
      {toolsElement && <div className="group-config__group-tools">{toolsElement}</div>}
    </div>
  );
};

GroupConfig.propTypes = {
  groupId: PropTypes.string,
  disabled: PropTypes.bool,
  isDesktop: PropTypes.bool,
  expanded: PropTypes.bool,
  onToggle: PropTypes.func,
  toolsElement: PropTypes.node,
  expired: PropTypes.bool,
};
