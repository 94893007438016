import {useCallback, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {DatePicker, getDateValue} from '~library/atoms/DatePicker';
import {Typography} from '~library/atoms/Typography';
import {selectMultipleDatesModalDates} from '~/SignUpSheets/reducers/sheet/selectors';
import {UPDATE_MULTIPLE_DATES_MODAL_CUSTOM_TAB} from '~/SignUpSheets/reducers/sheet/constants';

import './Custom.sass';

export const Custom = () => {
  const dispatch = useDispatch();
  const selectedDates = useSelector(selectMultipleDatesModalDates, shallowEqual);
  const onChange = useCallback(
    (_, dates) => {
      dispatch({
        type: UPDATE_MULTIPLE_DATES_MODAL_CUSTOM_TAB,
        payload: dates,
      });
    },
    [dispatch]
  );

  const formattedDateStrings = useMemo(
    () => selectedDates.map((d) => getDateValue(d)),
    [selectedDates]
  );

  return (
    <div className="add-multiple-dates__custom__form">
      <Typography variant="paragraph4">Select Dates</Typography>
      <DatePicker
        multi
        inline
        data-qa-id="add-multiple-dates-custom"
        value={formattedDateStrings}
        onChange={onChange}
        dateValueFormat="m/d/Y"
      />
    </div>
  );
};
