import {useCallback} from 'react';
import cx from 'classnames';
import {TextEditor} from '~library/organisms/TextEditor';
import {RecipientCount} from '~/SignUpSheets/components/Header/components/SheetOptions/MessageVolunteers/components/RecipientCount';
import {Footer} from '~/SignUpSheets/components/Header/components/SheetOptions/MessageVolunteers/components/Footer';

import './WriteMessage.sass';

export const WriteMessage = ({
  onBack,
  onNext,
  selectedUserIds,
  volunteersMap,
  message,
  setMessage,
}) => {
  const toolbarId = 'message-volunteers-toolbar';

  const handleNext = useCallback(() => {
    onNext();
  }, [onNext]);

  // Check if message is empty by removing HTML tags and whitespace
  const isMessageEmpty = !message?.replace(/<[^>]*>/g, '').trim();

  return (
    <div className="message-volunteers__step">
      <div className="message-volunteers__content">
        <TextEditor
          value={message}
          onChange={setMessage}
          placeholder="Write a message"
          data-qa-id="message-volunteers-text-editor"
          className="message-volunteers__text-editor"
          toolbar={
            <div className="rich-text-field-toolbar">
              <div className="rich-text-field-toolbar__options" id={toolbarId}>
                <button className="ql-bold" type="button" aria-label="bold" />
                <button className="ql-italic" type="button" aria-label="italic" />
                <button className="ql-underline" type="button" aria-label="underline" />
                <span className="ql-formats">
                  <button
                    className="ql-list"
                    value="bullet"
                    type="button"
                    aria-label="bullet list"
                  />
                  <button
                    className="ql-list"
                    value="ordered"
                    type="button"
                    aria-label="ordered list"
                  />
                </span>
              </div>
            </div>
          }
          toolbarId={toolbarId}
          containerClassName={cx('rich-text-field__container', 'write-message__container')}
          innerQuillClassName={cx('rich-text-field__container__quill', 'paragraph2')}
          toolbarAlign="left"
        />

        <RecipientCount selectedUserIds={selectedUserIds} volunteersMap={volunteersMap} />
      </div>
      <Footer
        onBack={onBack}
        onNext={handleNext}
        nextLabel="Preview message"
        nextDisabled={isMessageEmpty}
      />
    </div>
  );
};
