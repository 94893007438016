import {useSelector} from 'react-redux';
import {useIsLargishAndUp} from '~library/hooks/useMatchQuery';
import {DetailsThemeChanger} from './components/DetailsThemeChanger/DetailsThemeChanger';
import {DetailsTitle} from './components/DetailsTitle/DetailsTitle';
import {DetailsDivider} from './components/DetailsDivider/DetailsDivider';
import {DetailsWhen} from './components/DetailsWhen/DetailsWhen';
import {DetailsWhere} from './components/DetailsWhere/DetailsWhere';
import {DetailsOrganizedBy} from './components/DetailsOrganizedBy/DetailsOrganizedBy';
import {DetailsOrganizerNote} from './components/DetailsOrganizerNote/DetailsOrganizerNote';
import {ViewOnlyModeProvider} from './ViewOnlyModeProvider';
import {Link} from '~library/atoms/Link';
import {
  selectIsOrganizer,
  selectOrganizerEmail,
  selectTitle,
} from '~/SignUpSheets/reducers/sheet/selectors';

import './Details.sass';

export const Details = ({viewOnlyMode = false}) => {
  const isLargishAndUp = useIsLargishAndUp();
  const organizerEmail = useSelector(selectOrganizerEmail);
  const isOrganizer = useSelector(selectIsOrganizer);
  const sheetTitle = useSelector(selectTitle);
  const subject = `Question about ${encodeURIComponent(sheetTitle)}`;
  const mailtoLink = `mailto:${organizerEmail}?subject=${subject}`;
  const showEmailLink = organizerEmail && viewOnlyMode && !isOrganizer;

  return (
    <ViewOnlyModeProvider viewOnlyMode={viewOnlyMode}>
      <div className="signup-sheet__details" id="details">
        <DetailsThemeChanger />

        <div className="signup-sheet__details-content">
          <div className="signup-sheet__details-content__group--bigger-gap">
            <DetailsTitle />
            <DetailsWhen />
          </div>

          {(isLargishAndUp || viewOnlyMode) && (
            <div className="signup-sheet__details__bottom">
              <DetailsOrganizerNote />
              <DetailsWhere />
              <DetailsDivider />
              <DetailsOrganizedBy />
            </div>
          )}

          {showEmailLink && (
            <Link
              as="primary"
              data-qa-id="organizer-email"
              href={mailtoLink}
              target="_blank"
              variant="small"
              className="signup-sheet__details__actions"
            >
              Email Organizer
            </Link>
          )}

          {/* The order/placement of elements from above */}
          {!isLargishAndUp && !viewOnlyMode && (
            <>
              <DetailsWhere />
              <DetailsOrganizerNote />
              <DetailsDivider />
              <DetailsOrganizedBy />
            </>
          )}
        </div>
      </div>
    </ViewOnlyModeProvider>
  );
};
