import {useAutoAnimate} from '@formkit/auto-animate/react';
import cx from 'classnames';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {
  selectGroupIsPastOrFull,
  selectSignupOptionsByGroup,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {GroupConfig} from '~/SignUpSheets/Create/routes/Customize/components/GroupConfig/GroupConfig';
import {GroupAvailabilityTag} from '~/SignUpSheets/View/routes/VolunteerView/components/GroupAvailabilityTag/GroupAvailabilityTag';
import {VolunteerSignUpRow} from '~/SignUpSheets/View/routes/VolunteerView/components/VolunteerSignUpRow/VolunteerSignUpRow';
import {EditLineIcon} from '~library/svg/EditLineIcon';
import {Button} from '~library/atoms/Button';

import {pebble} from '~libSass/base/_exports.sass';

import './VolunteerViewSection.sass';

const VolunteerSignUpRowWrapper = ({
  groupId,
  option,
  openSignupOverlay,
  setIsEditing,
  allExpired,
  canceled,
  legacyDateTime,
  isDatetimeType,
  isPreviewMode,
  isDesktop,
  signedInId,
  newUserId,
  onEdit,
  expanded,
  onToggle,
}) =>
  isPreviewMode ? (
    <div className="signup-options__wrapper__preview-mode__option">
      <VolunteerSignUpRow
        key={option.uuid}
        rowData={option}
        openSignupOverlay={openSignupOverlay}
        setIsEditing={setIsEditing}
        allExpired={allExpired}
        canceled={canceled}
        hideForLegacyDateTime={legacyDateTime}
        split={isDatetimeType}
        isDesktop={isDesktop}
        signedInId={signedInId}
        newUserId={newUserId}
        expanded={expanded}
        onToggle={onToggle}
      />
      <Button
        variant="unstyled-button"
        className="signup-options__wrapper__preview-mode__option__edit-button"
        icon={<EditLineIcon ratio={0.5} color={pebble} />}
        onClick={onEdit(option)}
        data-qa-id={`edit-signup-option-${option.uuid}`}
      />
    </div>
  ) : (
    <VolunteerSignUpRow
      grouped={!!groupId}
      key={option.uuid}
      rowData={option}
      openSignupOverlay={openSignupOverlay}
      setIsEditing={setIsEditing}
      allExpired={allExpired}
      canceled={canceled}
      hideForLegacyDateTime={legacyDateTime}
      split={isDatetimeType}
      isDesktop={isDesktop}
      signedInId={signedInId}
      newUserId={newUserId}
      expanded={expanded}
      onToggle={onToggle}
    />
  );

const ANIMATION_TIME = 350;
export const VolunteerViewSection = ({
  groupId = null,
  option,
  openSignupOverlay,
  setIsEditing,
  allExpired,
  canceled,
  legacyDateTime,
  isDatetimeType,
  isPreviewMode = false,
  isDesktop,
  signedInId,
  newUserId,
  onEdit,
  trackOpenGroups,
  expanded = false,
}) => {
  const groupRef = useRef(null);
  const shouldScrollOnExpand = useRef(false);
  const [groupAnimation] = useAutoAnimate({
    duration: ANIMATION_TIME,
    easing: 'ease-in-out',
  });
  const signupOptionsInGroup = useSelector(
    selectSignupOptionsByGroup({groupId, sort: true, userId: signedInId || newUserId}),
    shallowEqual
  );

  const groupIsPastOrFull = useSelector(selectGroupIsPastOrFull(groupId));

  const toggleGroup = useCallback(
    (status = false) => {
      shouldScrollOnExpand.current = true; // This avoids auto-scrolling into view until the user has expanded it themselves
      trackOpenGroups?.(groupId ?? option.uuid, status);
    },
    [groupId, trackOpenGroups]
  );

  const GroupDetails = useMemo(
    () => (
      <GroupConfig
        groupId={groupId}
        isDesktop={isDesktop}
        disabled
        expired={allExpired || groupIsPastOrFull}
        previewMode
        expanded={expanded}
        onToggle={toggleGroup}
      />
    ),
    [groupId, isDesktop, allExpired, groupIsPastOrFull, expanded, toggleGroup]
  );

  const GroupRows = useMemo(
    () => (
      <div ref={groupAnimation}>
        {expanded ? (
          <div className="signup-sheet__volunteer-view-section__rows">
            {signupOptionsInGroup.map((o) => (
              <VolunteerSignUpRowWrapper
                groupId={groupId}
                key={o.uuid}
                option={o}
                openSignupOverlay={openSignupOverlay}
                setIsEditing={setIsEditing}
                allExpired={allExpired || groupIsPastOrFull}
                canceled={canceled}
                legacyDateTime={legacyDateTime}
                isDatetimeType={isDatetimeType}
                isPreviewMode={isPreviewMode}
                isDesktop={isDesktop}
                signedInId={signedInId}
                newUserId={newUserId}
                onEdit={onEdit}
              />
            ))}
          </div>
        ) : (
          <GroupAvailabilityTag
            groupId={groupId}
            className="signup-sheet__volunteer-view-section__group-availability-tag"
          />
        )}
      </div>
    ),
    [
      signupOptionsInGroup,
      groupId,
      openSignupOverlay,
      setIsEditing,
      allExpired,
      canceled,
      legacyDateTime,
      isDatetimeType,
      isPreviewMode,
      isDesktop,
      signedInId,
      newUserId,
      onEdit,
      groupIsPastOrFull,
      expanded,
    ]
  );

  useEffect(() => {
    if (groupRef.current && expanded && shouldScrollOnExpand.current) {
      setTimeout(() => {
        groupRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, ANIMATION_TIME);
    }
  }, [expanded, groupId]);

  if (option && !Array.isArray(option)) {
    return (
      <VolunteerSignUpRowWrapper
        option={option}
        openSignupOverlay={openSignupOverlay}
        setIsEditing={setIsEditing}
        allExpired={allExpired}
        canceled={canceled}
        legacyDateTime={legacyDateTime}
        isDatetimeType={isDatetimeType}
        isPreviewMode={isPreviewMode}
        isDesktop={isDesktop}
        signedInId={signedInId}
        newUserId={newUserId}
        onEdit={onEdit}
        expanded={expanded}
        onToggle={toggleGroup}
      />
    );
  }
  if (!groupId) return null;
  return (
    <div className={cx('signup-sheet__volunteer-view-section', 'grouped')} ref={groupRef}>
      {GroupDetails}
      {GroupRows}
    </div>
  );
};
