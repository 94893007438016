import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Input} from '~library/atoms/Input';
import {ResponsivePanel} from '~library/organisms/ResponsivePanel';
import {largishAndUp} from '~libSass/base/_exports.sass';
import {Button} from '~library/atoms/Button';
import {useMatchQuery} from '~library/hooks/useMatchQuery';
import {selectAddress} from '~/SignUpSheets/reducers/sheet/selectors';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {COUNTRYOPTIONS, USA_STATES} from './constants';
import './AddressOverlay.sass';

export const AddressOverlay = ({closeOverlay, isOpen}) => {
  const {updateSheetDetails} = useUpdateSheet();
  const {matches: isDesktop} = useMatchQuery(`(min-width: ${largishAndUp})`);

  const addressFromStore = useSelector(selectAddress, shallowEqual);
  const [address, setAddress] = useState(addressFromStore || {});

  const onAddressFieldChange = useCallback(
    (field) =>
      ({target: {value}}) =>
        setAddress({...address, [field]: value}),
    [address]
  );

  const onSelectAddressChange = useCallback(
    (field, value) => {
      if (field === 'country' && address.state !== '') {
        setAddress({...address, [field]: value, state: ''});
      } else {
        setAddress({...address, [field]: value});
      }
    },
    [address]
  );

  const onSaveHandler = useCallback(() => {
    updateSheetDetails({payload: {address}});
    closeOverlay();
  }, [address, closeOverlay, updateSheetDetails]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSaveHandler();
    }
  };

  useEffect(() => setAddress(addressFromStore), [addressFromStore]);

  return (
    <ResponsivePanel
      isOpen={isOpen}
      onClose={closeOverlay}
      title="Location"
      modalId="address"
      closeQaId="event-location-exit"
      showCover
      portalMode
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onKeyDown={handleKeyDown}>
        <div className="one-step-flow__overlay-body">
          <OverlayContent
            onSelectAddressChange={onSelectAddressChange}
            address={address}
            onAddressFieldChange={onAddressFieldChange}
            isMobile={!isDesktop}
          />
        </div>
        <div className="one-step-flow__overlay-footer">
          <Button
            data-qa-id="event-location-save"
            variant="primary"
            onClick={onSaveHandler}
            fullWidth={!isDesktop}
            size="large"
            formSubmit
          >
            Save Location
          </Button>
        </div>
      </div>
    </ResponsivePanel>
  );
};

const OverlayContent = ({address, onSelectAddressChange, onAddressFieldChange, isMobile}) => (
  <div className="address-overlay__content">
    <Input
      label="Street"
      id="street"
      name="street"
      data-qa-id="event-address-street"
      error={null}
      type="text"
      value={address.street ?? ''}
      onChange={onAddressFieldChange('street')}
      placeholder="Street address"
      showOptionalLabel
    />
    <div
      className={
        address.country === 'US'
          ? 'address-overlay__multiline_input'
          : 'address-overlay__input_wrapper_expand'
      }
    >
      <Input
        label="City"
        id="city"
        name="city"
        data-qa-id="event-address-city"
        type="text"
        value={address.city ?? ''}
        onChange={onAddressFieldChange('city')}
        placeholder="City"
        showOptionalLabel
      />
      {address.country === 'US' && (
        <div className="address-overlay__select_input">
          <label htmlFor="state" className="label2 small input-label">
            {' '}
            State <span className="label3">(Optional)</span>
          </label>
          <Input
            data-qa-id="event-address-state"
            id="state"
            name="state"
            options={
              !isMobile ? USA_STATES : USA_STATES.map((state) => ({...state, label: state.value}))
            }
            onChange={(newValue) => onSelectAddressChange('state', newValue.value)}
            placeholder="-"
            type="select"
            value={address.state}
            isSearchable={false}
            isClearable={false}
          />
        </div>
      )}
      {address.country !== 'US' && (
        <div className="address-overlay__input_wrapper">
          <Input
            label="Province"
            id="state"
            name="state"
            data-qa-id="event-address-state"
            placeholder="Province"
            error={null}
            type="text"
            value={address.state}
            onChange={onAddressFieldChange('state')}
            showOptionalLabel
          />
        </div>
      )}
    </div>
    <Input
      label={address.country === 'US' ? 'Zip Code' : 'Postal Code'}
      id="zip"
      name="zip"
      data-qa-id="event-address-zip"
      error={null}
      type="text"
      value={address.zip ?? ''}
      onChange={onAddressFieldChange('zip')}
      placeholder={address.country === 'US' ? 'Zip Code' : 'Postal Code'}
      showOptionalLabel
    />

    <div className="address-overlay__select_input">
      <label htmlFor="country" className="label2 small input-label">
        Country<span className="label3">(Optional)</span>
      </label>
      <Input
        id="country"
        type="select"
        name="country"
        options={COUNTRYOPTIONS}
        placeholder="Country"
        value={address.country || COUNTRYOPTIONS[0].value}
        onChange={(newValue) => onSelectAddressChange('country', newValue.value)}
        data-qa-id="event-address-country"
        isSearchable={false}
        isClearable={false}
      />
    </div>
  </div>
);
