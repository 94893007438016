import {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import {Button} from '~library/atoms/Button';
import {ChevronIcon} from '~library/svg/ChevronIcon';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {sanitizeString} from '~/common/utils/sanitize_html';
import {Typography} from '~library/atoms/Typography';
import {DetailsDivider} from '~/SignUpSheets/components/Details/components/DetailsDivider/DetailsDivider';

import {linkColor} from '~libSass/base/_exports.sass';
import './DetailsOrganizerNoteViewOnly.sass';

export const DetailsOrganizerNoteViewOnly = ({organizerNote}) => {
  const [noteExpanded, setNoteExpanded] = useState(false);
  const [noteIsExpandable, setNoteIsExpandable] = useState(false);
  const noteRef = useRef(null);
  const isLargishAndUp = useIsLargishAndUp();

  useEffect(() => {
    const noteEl = noteRef.current?.firstElementChild;

    if (!noteEl) return;
    setNoteIsExpandable(noteEl.clientHeight > (isLargishAndUp ? 80 : 68));
  }, [isLargishAndUp, organizerNote]);

  if (!organizerNote) return null;

  return (
    <>
      <DetailsDivider />
      <div className="signup-sheet__view-details__organizer-note-wrapper">
        <div
          className={cx('details-organizer-note', {
            'details-organizer-note--expanded': noteExpanded,
            'details-organizer-note--hidden': !organizerNote,
          })}
          ref={noteRef}
        >
          <Typography
            variant={isLargishAndUp ? 'paragraph1' : 'paragraph2'}
            className={cx('signup-sheet__rendered-rich-text', 'details-organizer-note__note-text', {
              truncated: noteIsExpandable && !noteExpanded,
            })}
            color="pebble"
          >
            {sanitizeString(organizerNote)}
          </Typography>
        </div>

        {noteIsExpandable && (
          <Button
            className="details-organizer-note__show-more"
            variant="link"
            onClick={() => setNoteExpanded(!noteExpanded)}
            data-qa-id="organizer-note-show-more"
            size={isLargishAndUp ? 'large' : 'small'}
          >
            Show {noteExpanded ? 'less' : 'more'}
            <ChevronIcon color={linkColor} ratio={0.5} direction={noteExpanded ? 'up' : 'down'} />
          </Button>
        )}
      </div>
    </>
  );
};
