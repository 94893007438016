import {useIsLargeAndUp} from '~/common/hooks/useMatchQuery';
import {OptionGroup} from './OptionGroup/OptionGroup';
import {ExportList} from './buttons/ExportList';
import {CancelSheet} from './buttons/CancelSheet';
import {PrintSheet} from './buttons/PrintSheet';
import {CopyShareableLink} from './buttons/CopyShareableLink';
import {ViewSheet} from './buttons/ViewSheet';
import {EditSheet} from './buttons/EditSheet';
import {MessageHistory} from './buttons/MessageHistory';
import {MessageVolunteersButton} from './buttons/MessageVolunteers';

export const OrganizerTools = () => {
  const isLargeAndUp = useIsLargeAndUp();

  return (
    <div className="sheet-options">
      <OptionGroup title="Sheet Management">
        {!isLargeAndUp && (
          <>
            <CopyShareableLink />
            <ViewSheet />
          </>
        )}
        <EditSheet />
      </OptionGroup>

      <OptionGroup title="Volunteer Management">
        <MessageVolunteersButton />
        <MessageHistory />
        <PrintSheet />
        <ExportList />
        <CancelSheet />
      </OptionGroup>
    </div>
  );
};
