import {useCallback, useEffect, useState} from 'react';
import {useIsLargishAndUp} from '~library/hooks/useMatchQuery';
import {BottomSheet} from '~library/molecules/BottomSheet';
import {ResponsiveModal} from '~library/organisms/ResponsiveModal';
import {Button} from '~library/atoms/Button';
import {Steps} from './Steps/Steps';

import './MessageVolunteers.sass';

export const MessageVolunteers = ({visible, onClose}) => {
  const isLargishAndUp = useIsLargishAndUp();
  const [currentStep, setCurrentStep] = useState(1);
  const [message, setMessage] = useState('');

  const onClickCancel = useCallback(() => {
    onClose();
    setCurrentStep(1);
    setMessage('');
  }, [onClose]);

  const getStepTitle = () => {
    switch (currentStep) {
      case 1:
        return isLargishAndUp
          ? 'Step 1: Select volunteers to message'
          : 'Step 1: Select volunteers';
      case 2:
        return 'Step 2: Write message';
      case 3:
        return isLargishAndUp ? 'Step 3: Preview and send message' : 'Step 3: Preview and send';
      default:
        return '';
    }
  };

  useEffect(() => {
    document.body.classList.add('no-overflow');

    return () => {
      document.body.classList.remove('no-overflow');
    };
  }, []);

  const modalContent = (
    <Steps
      onClose={onClickCancel}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      message={message}
      setMessage={setMessage}
    />
  );

  if (isLargishAndUp) {
    return (
      <ResponsiveModal
        title={getStepTitle()}
        isOpen={visible}
        onClose={onClickCancel}
        className="message-volunteers__modal"
        modalId="message-volunteers__modal"
        closeOnClickOutside={false}
      >
        {modalContent}
      </ResponsiveModal>
    );
  }

  return (
    <>
      {visible && (
        <Button
          className="unstyled-button mobile-menu__overlay"
          onClick={onClose}
          type="button"
          aria-label="Close message volunteers overlay"
          variant="transparent"
        />
      )}
      <BottomSheet
        in={visible}
        title={getStepTitle()}
        content={modalContent}
        onClose={onClickCancel}
        className="message-volunteers__bottom-sheet"
      />
    </>
  );
};
