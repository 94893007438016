import moment from 'moment-timezone';
import {Input} from '~library/atoms/Input';

const now = moment();
const timezoneList = moment.tz
  .names()
  .sort((a, b) => {
    const zoneA = moment.tz(now, a);
    const zoneB = moment.tz(now, b);
    return zoneA.utcOffset() - zoneB.utcOffset();
  })
  .map((tz) => {
    const zone = moment.tz(now, tz);
    const offset = zone.format('Z');
    return {
      offset,
      label: `${tz} (GMT${offset})`,
      value: tz,
    };
  });

export const getUserTimezone = (tz) => {
  let timezone;
  if (tz?.value || tz?.name) {
    timezone = timezoneList.find((t) => t.value === tz?.value || t.value === tz?.name);
  } else {
    timezone = timezoneList.find((t) => t.value === moment.tz.guess());
  }
  return timezone || timezoneList[0];
};

export const getTimezoneAbbreviation = (timezone) => {
  const tzAbbr = moment.tz(now, timezone)?.zoneAbbr();
  return /^[A-Z]+$/.test(tzAbbr) ? tzAbbr : timezone;
};

const getOptionLabel = (opt, selectedTimezone) => {
  if (opt?.value === selectedTimezone) {
    return getTimezoneAbbreviation(opt.value);
  }
  return opt.label;
};

export const TimezoneSelect = ({
  autoFocus,
  timezone,
  label,
  onBlur,
  onChange,
  error,
  'data-qa-id': dataQaId,
  inputClassName,
  live = false,
}) => {
  const tz = getUserTimezone(timezone);

  return (
    <Input
      label={label}
      data-qa-id={dataQaId || 'timezone-selector'}
      error={error}
      id="time-zone"
      isClearable={false}
      name="time-zone"
      options={timezoneList}
      onBlur={onBlur}
      onChange={onChange}
      placeholder="Time Zone"
      type="select"
      value={tz.value}
      className="builder__timezone-select"
      inputClassName={inputClassName}
      getOptionLabel={(opt) => getOptionLabel(opt, tz.value)}
      autoFocus={autoFocus}
      isSearchable
      readonly
      live={live}
    />
  );
};
