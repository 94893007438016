import moment from 'moment-timezone';
import {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Typography} from '~library/atoms/Typography';
import {pluralize} from '~/common/utils';
import {useMultipleSlots} from '~/SignUpSheets/Create/routes/Customize/components/AddMultipleSlots/hooks/useMultipleSlots';
import {selectMultipleDatesModalDates} from '~/SignUpSheets/reducers/sheet/selectors';
import {formatDateShort} from '~/SignUpSheets/utils/misc';

import './Summary.sass';

export const Summary = () => {
  const dates = useSelector(selectMultipleDatesModalDates, shallowEqual);
  const {generateSlots} = useMultipleSlots();

  const slots = generateSlots();

  const formattedDates = useMemo(
    () => [...dates].map((date) => moment(date)).sort((a, b) => a.diff(b)),
    [dates]
  );

  return formattedDates.map((date, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={`summary-date-${index}`} className="multiple-dates__summary-date">
      <div className="multiple-dates__summary-date__title">
        <Typography variant="header3">{formatDateShort(date)}</Typography>
      </div>
      <div className="multiple-dates__summary-date__slots">
        {slots.map((slot) => (
          <div key={slot.slot_id} className="multiple-dates__summary-date__slot">
            <Typography variant="list1">
              {slot.start_time} - {slot.end_time}
            </Typography>
            <Typography variant="list1">{pluralize('signup', slot.quantity)}</Typography>
          </div>
        ))}
      </div>
    </div>
  ));
};
