import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment-timezone';
import {Button} from '~library/atoms/Button';
import {DatePicker} from '~library/atoms/DatePicker';
import {TimePicker} from '~library/atoms/TimePicker';
import {ResponsivePanel} from '~library/organisms/ResponsivePanel';
import {useMatchQuery} from '~library/hooks/useMatchQuery';
import {
  selectEndDate,
  selectStartDate,
  selectSheetTimezone,
  selectSheetHasPreexistingEndDate,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {AddRemoveEndDate} from '~/SignUpSheets/components/DateTimeOverlay/AddRemoveEndDate';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {TimezoneSelect} from '~/SignUpSheets/components/TimezoneSelect/TimezoneSelect';
import {largishAndUpQuery} from '~libSass/base/_exports.sass';

import './DateTimeOverlay.sass';

const endDateTimeBeforeStartDateDate = ({start, end}) => {
  if (!end) return false;
  return moment(start).diff(moment(end), 'minutes') >= 0;
};

const startDateTimeBeforeToday = (start) => {
  if (!start) return false;
  const diff = moment().diff(start, 'minutes');
  return diff >= 0;
};

export const DateTimeOverlay = ({closeOverlay, isOpen}) => {
  const {updateSheetDetails} = useUpdateSheet();

  const startDateFromStore = useSelector(selectStartDate);
  const [start, setStart] = useState(startDateFromStore);
  const endStateFromStore = useSelector(selectEndDate);
  const [end, setEnd] = useState(endStateFromStore);
  const sheetHasPreexistingEndDate = useSelector(selectSheetHasPreexistingEndDate);
  const timezoneFromStore = useSelector(selectSheetTimezone);
  const [timezone, setTimezone] = useState(timezoneFromStore);

  const startDateError = startDateTimeBeforeToday(start);
  const endDateError = endDateTimeBeforeStartDateDate({start, end});
  const {matches: isDesktop} = useMatchQuery(`(${largishAndUpQuery})`);

  const onTimezoneChange = useCallback(
    (newTimezone) => {
      setTimezone({
        name: newTimezone.value,
        offset: newTimezone.offset,
      });
      const currentStart = moment.tz(start, timezone?.name);
      const startWithNewTimezone = currentStart.tz(newTimezone.value, true);
      setStart(startWithNewTimezone.toISOString());
      if (end) {
        const currentEnd = moment.tz(end, timezone?.name);
        const endWithNewTimezone = currentEnd.tz(newTimezone.value, true);
        setEnd(endWithNewTimezone.toISOString());
      }
    },
    [start, end]
  );

  const onSaveHandler = useCallback(() => {
    updateSheetDetails({
      payload: {
        start,
        end,
        timezone,
      },
      errorsToClear: [['start'], ['end']],
    });
    closeOverlay();
  }, [start, end, closeOverlay, timezone, updateSheetDetails]);

  useEffect(() => {
    setStart(startDateFromStore);
  }, [startDateFromStore]);

  useEffect(() => {
    setEnd(endStateFromStore);
  }, [endStateFromStore]);

  useEffect(() => {
    setTimezone(timezoneFromStore);
  }, [timezoneFromStore]);

  return (
    <ResponsivePanel
      isOpen={isOpen}
      onClose={closeOverlay}
      title="Date & Time"
      modalId="sheet-date-time"
      closeQaId="date-time--exit"
      showCover
      portalMode
    >
      <OverlayContent
        start={start}
        end={end}
        sheetHasPreexistingEndDate={sheetHasPreexistingEndDate}
        timezone={timezone}
        onStartDateChange={setStart}
        onStartEndDateChange={setEnd}
        onTimezoneChange={onTimezoneChange}
        startDateError={startDateError}
        endDateError={endDateError}
      />
      <Button
        data-qa-id="date-time-save"
        disabled={!start || startDateError || endDateError}
        variant="primary"
        onClick={onSaveHandler}
        fullWidth={!isDesktop}
        size="large"
      >
        Save Date & Time
      </Button>
    </ResponsivePanel>
  );
};

const DateTimeZone = ({
  label = 'Date',
  timezone,
  dataQaId,
  onSelectTimezone,
  date,
  onSelectDate,
  errors,
}) => {
  const onDateChangeHandler = useCallback(
    ([newDate]) => {
      let hrs;
      let mins = 0;

      const dateObj = moment.utc(date).tz(timezone?.name);
      if (moment(date).isValid()) {
        hrs = dateObj.hours();
        mins = dateObj.minutes();
      } else {
        hrs = 18; // 6:00pm
      }
      const dateWithTimeAndZone = moment.utc(newDate).tz(timezone?.name).hours(hrs).minutes(mins);
      onSelectDate(dateWithTimeAndZone.toISOString());
    },
    [date, onSelectDate]
  );

  const onTimeChangeHandler = useCallback(
    ({value: newTime}) => {
      const parsed = parseFloat(newTime.replace(':', '.')).toFixed(2);
      // eslint-disable-next-line prefer-const
      let [hr, min] = parsed.split('.').map((t) => parseInt(t, 10));

      if (newTime.includes('PM') && hr !== 12) hr = parseInt(hr, 10) + 12;
      if (newTime.includes('AM') && hr === 12) hr = 0;

      const dateWithTimeAndZone = moment.utc(date).tz(timezone?.name).hours(hr).minutes(min);
      onSelectDate(dateWithTimeAndZone.toISOString());
    },
    [date, onSelectDate]
  );

  return (
    <>
      <DatePicker
        label={label}
        id={dataQaId}
        name="date"
        data-qa-id={`${dataQaId}-date`}
        error={errors?.date}
        className="flatpickr-input"
        type="text"
        value={date && moment.tz(date, timezone?.name).format('YYYY-MM-DD')}
        placeholder="Date"
        required
        onChange={onDateChangeHandler}
      />
      <div className="date-time__time-timezone flex-row-gap">
        <TimePicker
          label="Time"
          id="time"
          name="time"
          data-qa-id={`${dataQaId}-time`}
          error={errors?.time}
          value={date && moment.tz(date, timezone?.name).format('h:mm A')}
          onChange={onTimeChangeHandler}
          placeholder="6:00 PM"
          required
        />
        <TimezoneSelect
          label="Time Zone"
          onChange={onSelectTimezone}
          timezone={timezone}
          data-qa-id={`${dataQaId}-timezone`}
          error={null}
        />
      </div>
    </>
  );
};

const OverlayContent = ({
  start,
  end,
  sheetHasPreexistingEndDate,
  timezone,
  onStartDateChange,
  onStartEndDateChange,
  onTimezoneChange,
  startDateError,
  endDateError,
}) => (
  <div className="date-time__overlay overlay-box">
    <DateTimeZone
      date={start}
      dataQaId="event"
      onSelectDate={onStartDateChange}
      errors={{date: startDateError ? 'Start date has to be in the future' : null}}
      timezone={timezone}
      onSelectTimezone={onTimezoneChange}
    />
    {end && (
      <DateTimeZone
        date={end}
        dataQaId="event-end"
        errors={{date: endDateError ? 'End date cannot be before start date' : null}}
        label="End Date"
        onSelectDate={onStartEndDateChange}
        timezone={timezone}
        onSelectTimezone={onTimezoneChange}
      />
    )}
    {sheetHasPreexistingEndDate && <AddRemoveEndDate startDate={start} />}
  </div>
);
