import {reduxPromise} from '~/CreateFabricInvitation/middleware/PromiseResolver';
import {
  HIDE_LOGIN_MODAL,
  showLoginModal,
  USER_LOGGED_IN,
} from '~library/organisms/LoginModal/reducer';

export const loginModalPromise = (dispatch) => {
  const promise = reduxPromise(null, [USER_LOGGED_IN], [HIDE_LOGIN_MODAL]);
  dispatch(showLoginModal());
  return promise;
};
