import {useCallback, useState} from 'react';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetOption} from '~/SignUpSheets/components/Header/components/SheetOptions/components/SheetOption/SheetOption';
import {SentMessagesMenu} from '~/SignUpSheets/components/Header/components/SheetOptions/SentMessagesMenu';
import {MessageLineIconSent} from '~library/svg/MessageLineIconSent';

export const MessageHistory = () => {
  const {closeOptions} = useSheetOptions();
  const [showSentMessagesModal, setShowSentMessagesModal] = useState(false);

  const openMessageHistory = useCallback(() => setShowSentMessagesModal(true), []);

  const closeMessageHistory = useCallback(() => {
    setShowSentMessagesModal(false);
    closeOptions();
  }, [closeOptions]);

  return (
    <>
      <SheetOption
        title="View Sent Messages"
        description="See all sent messages here"
        icon={<MessageLineIconSent ratio={0.5} />}
        onClick={openMessageHistory}
      />

      {showSentMessagesModal && (
        <SentMessagesMenu visible={showSentMessagesModal} onClose={closeMessageHistory} />
      )}
    </>
  );
};
