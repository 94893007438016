import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {ClipboardIcon} from '~library/svg/ClipboardIcon';
import {shouldPrintInline} from '~/SignUpSheets/utils/print';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetOption} from '~/SignUpSheets/components/Header/components/SheetOptions/components/SheetOption/SheetOption';

export const PrintSheet = () => {
  const sheetId = useSelector(selectSheetId);
  const {onGetPrintableSheet} = useSheetOptions();
  const {closeOptions} = useSheetOptions();

  const onPrintSheet = useCallback(() => {
    onGetPrintableSheet();
    closeOptions();
  }, [onGetPrintableSheet, closeOptions]);

  return (
    <SheetOption
      title="Get Printable Sheet"
      description="Download a printable version of your signup sheet to use at your event."
      icon={<ClipboardIcon ratio={0.4} />}
      href={shouldPrintInline ? null : `/signup-sheet/${sheetId}/print`}
      onClick={shouldPrintInline ? onPrintSheet : null}
    />
  );
};
