import {ResponsivePanel} from '~library/organisms/ResponsivePanel';
import {useMatchQuery} from '~library/hooks/useMatchQuery';
import {BottomSheet} from '~library/molecules/BottomSheet';

import {OverlayContent} from './components/OverlayContent';

import {mediumAndUpQuery} from '~libSass/base/_exports.sass';
import './ThemePickerOverlay.sass';

export const ThemePickerOverlay = ({closeOverlay, isOpen}) => {
  const {matches: isDesktop} = useMatchQuery(`(${mediumAndUpQuery})`);

  return isDesktop ? (
    <ResponsivePanel
      isOpen={isOpen}
      onClose={closeOverlay}
      title="Change theme"
      modalId="theme-picker"
      closeQaId="sheet-theme-picker-exit"
      showCover
      portalMode
      className="EviteModal__Content-HalfPage"
    >
      <OverlayContent />
    </ResponsivePanel>
  ) : (
    <BottomSheet
      in={isOpen}
      content={<OverlayContent handleClose={closeOverlay} />}
      onClose={closeOverlay}
      className="theme-picker-overlay__bottom-sheet"
    />
  );
};
