import {api} from './api';

export const sheetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBlueprint: builder.query({
      query: (id) => ({url: `${window.location.origin}/signup-sheet/blueprint/${id}`}),
      providesTags: (result, error, id) => [{type: 'Blueprint', id}],
    }),
    getSheet: builder.query({
      query: (id) => ({url: `/signup/${id}/`}),
    }),
    saveSheetUpdates: builder.mutation({
      query(body) {
        return {
          url: body.id ? `/signup/${body.id}/` : `/signup/`,
          method: body.id ? 'PUT' : 'POST',
          body: JSON.stringify(body),
        };
      },
    }),
    publishSheet: builder.mutation({
      query({id, organizer_id: organizerId, blueprint_id: blueprintId}) {
        return {
          url: `/signup/${id}/publish/`,
          method: 'POST',
          body: JSON.stringify({
            utm: window.getUTM(),
            id,
            organizer_id: organizerId,
            blueprint_id: blueprintId,
          }),
        };
      },
    }),
    cancelSheet: builder.mutation({
      query(id) {
        return {
          url: `/signup/${id}/cancel/`,
          method: 'POST',
        };
      },
    }),
    archiveSheet: builder.mutation({
      query(id) {
        return {
          url: `/signup/${id}/archive/`,
          method: 'POST',
        };
      },
    }),
    saveSignups: builder.mutation({
      query: ({id, signups}) => ({
        url: `/signup/${id}/signups/`,
        method: 'POST',
        body: JSON.stringify(signups),
      }),
    }),
    removeSignup: builder.mutation({
      query: ({id, row_id: rowId}) => ({
        url: `/signup/${id}/row/${rowId}/`,
        method: 'DELETE',
      }),
    }),
    updateSignups: builder.mutation({
      query: ({id, signups}) => ({
        url: `/signup/${id}/rows/`,
        method: 'PUT',
        body: JSON.stringify(signups),
      }),
    }),
    getMessages: builder.query({
      query: (id) => ({url: `/signup/${id}/message/`}),
    }),
    sendMessageToVolunteers: builder.mutation({
      query: ({id, volunteers, message}) => ({
        url: `/signup/${id}/message/`,
        method: 'POST',
        body: JSON.stringify({
          volunteers,
          message,
        }),
      }),
    }),
    exportSheetToCSV: builder.query({
      query: (id) => ({
        url: `/signup/${id}/csv`,
        method: 'GET',
        headers: {
          'Content-Type': 'text/csv',
        },
        responseHandler: async (response) => {
          const url = window.URL.createObjectURL(await response.blob());
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = url;
          a.download = `signup_sheet.csv`;
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        },
      }),
    }),
  }),
});

export const {
  useLazyGetBlueprintQuery,
  useLazyGetSheetQuery,
  useSaveSheetUpdatesMutation,
  usePublishSheetMutation,
  useCancelSheetMutation,
  useArchiveSheetMutation,
  useSaveSignupsMutation,
  useRemoveSignupMutation,
  useUpdateSignupsMutation,
  useLazyGetMessagesQuery,
  useLazyExportSheetToCSVQuery,
  useSendMessageToVolunteersMutation,
} = sheetApi;
