import {mintChipLight} from '~libSass/base/_exports.sass';

const keyframes = [{backgroundColor: mintChipLight, offset: 0.5}];
const timing = {duration: 1000, iterations: 1};

export const flashGreen = (selectorOrElement) => {
  const element =
    selectorOrElement instanceof Element
      ? selectorOrElement
      : document.querySelector(selectorOrElement);
  if (element) {
    element.animate(keyframes, timing);
  }
};
