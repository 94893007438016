import PropTypes from 'prop-types';
import {useCallback, useContext, useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import isEqual from 'lodash/isEqual';
import {CheckmarkIcon} from '~library/svg/CheckmarkIcon';
import {LinkIcon} from '~library/svg/LinkIcon';
import {
  selectGateUnlockedForSheet,
  selectIsInEditMode,
  selectLocalWishlists,
  selectSheetId,
  selectWishlistOption,
  selectWishlists,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {getLoggedUserId} from '~/common/fabric/User/reducers/user';
import {Button} from '~library/atoms/Button';
import {Spinner} from '~library/atoms/Spinner';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {ConfirmNotifyModal} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/ConfirmNotifyModal';
import {Divider} from '~library/atoms/Divider';
import {SheetOptions} from './components/SheetOptions/SheetOptions';
import {PreviewToggle} from './components/PreviewToggle/PreviewToggle';
import {useIsLargeAndUp, useIsLargishAndUp} from '~library/hooks/useMatchQuery';

import {white} from '~libSass/base/_exports.sass';
import './HeaderActionButtons.sass';

export const HeaderActionButtons = ({
  handleShare,
  handlePrint,
  trackChangeUpstream,
  copied = false,
}) => {
  const {flow} = useContext(SignUpSheetContext);
  const {autoSave, isSavingSheet, isPublishingSheet, isSaveSuccess, isSaveError, onSave} =
    useSheetActions();
  const lgScreens = useIsLargeAndUp();
  const isDesktop = useIsLargishAndUp();
  const sheetId = useSelector(selectSheetId);
  const isInEditMode = useSelector(selectIsInEditMode);
  const wishlists = useSelector(selectWishlists, shallowEqual);
  const wishlistOption = useSelector(selectWishlistOption);
  const {updateSheetDetails} = useUpdateSheet();
  const isLoggedIn = useSelector(getLoggedUserId);
  const localWishlists = useSelector(selectLocalWishlists, shallowEqual);
  const showNotifyVolunteersModal = useSelector(selectGateUnlockedForSheet);
  const [triggerConfirmNotifyOnSave, setTriggerConfirmNotifyOnSave] = useState(false);
  const [showConfirmNotifyModal, setShowConfirmNotifyModal] = useState(false);
  const [notifyVolunteersOfChanges, setNotifyVolunteersOfChanges] = useState(null);
  const isCreateOrEdit = ['create', 'edit'].includes(flow);

  const handleSaveOrPublish = useCallback(
    () =>
      new Promise((resolve) => {
        const usingWishlists = wishlistOption === 'my-wishlist';
        const hasEmptyWishlist = localWishlists.some((w) => w.trim() === '');
        if ((usingWishlists && hasEmptyWishlist) || !isEqual(wishlists, localWishlists)) {
          updateSheetDetails({
            payload: {
              has_unsaved_wishlists: true,
            },
          });
          resolve();
        } else {
          updateSheetDetails({
            payload: {
              has_unsaved_wishlists: false,
            },
            errorsToClear: [['wishlists']],
          });
          if (isInEditMode) {
            onSave(false)
              .then((result) => {
                if (result) {
                  window.location.href = `/signup-sheet/${sheetId}`;
                }
              })
              .catch(evite.error)
              .finally(resolve);
          } else {
            autoSave(true).catch(evite.error).finally(resolve);
          }
        }
      }),
    [autoSave, wishlistOption, localWishlists, onSave, updateSheetDetails, wishlists, isInEditMode]
  );

  const handleNotifyModalBeforeSave = useCallback(() => {
    // EVT-31532: Some finagling needed to allow error handling before notify modal
    if (isSaveError && triggerConfirmNotifyOnSave) {
      handleSaveOrPublish(); // to trigger validation modal
    }
    if (!isSaveError && triggerConfirmNotifyOnSave) {
      setShowConfirmNotifyModal(true);
    } else {
      handleSaveOrPublish();
    }
  }, [isSaveError, triggerConfirmNotifyOnSave, handleSaveOrPublish]);

  const handlePrimaryAction = useCallback(() => {
    setNotifyVolunteersOfChanges(true);
    setShowConfirmNotifyModal(false);
  }, []);

  const handleSecondaryAction = useCallback(() => {
    setNotifyVolunteersOfChanges(false);
    setShowConfirmNotifyModal(false);
  }, []);

  useEffect(() => {
    if (notifyVolunteersOfChanges !== undefined && notifyVolunteersOfChanges !== null) {
      updateSheetDetails({
        payload: {
          notify_volunteers: notifyVolunteersOfChanges,
        },
      });
      handleSaveOrPublish();
    }
  }, [notifyVolunteersOfChanges]);

  useEffect(() => {
    setTriggerConfirmNotifyOnSave(showNotifyVolunteersModal);
  }, [showNotifyVolunteersModal]);

  const showCreateFlowActions = isCreateOrEdit && lgScreens;

  const showVolunteerFlowActions = lgScreens && flow === 'view';

  if (!showCreateFlowActions && !isCreateOrEdit && !showVolunteerFlowActions) return null;

  return (
    <div className="signup-sheet__header__buttons">
      {showCreateFlowActions && (
        <>
          {isDesktop ? <PreviewToggle /> : null}
          {!isInEditMode && (
            <Button
              data-qa-id="save-progress"
              className="signup-sheet__header__action-button"
              variant="secondary"
              onClick={() => autoSave()}
              disableProcessing
              disabled={isSavingSheet || isPublishingSheet}
            >
              Save
              <Spinner isLoading={isSavingSheet} isSuccess={isSaveSuccess} isError={isSaveError} />
            </Button>
          )}
          <Button
            data-qa-id={isInEditMode ? 'save-sheet-cta' : 'publish-sheet-cta'}
            variant="brand-primary"
            disableProcessing
            disabled={isSavingSheet || isPublishingSheet || !sheetId || !isLoggedIn}
            onClick={isInEditMode ? handleNotifyModalBeforeSave : handleSaveOrPublish}
          >
            {isInEditMode ? 'Save changes' : 'Publish'}
            <Spinner isLoading={isSavingSheet} isSuccess={isSaveSuccess} isError={isSaveError} />
          </Button>
          {showConfirmNotifyModal && (
            <ConfirmNotifyModal
              onPrimaryAction={handlePrimaryAction}
              onSecondaryAction={handleSecondaryAction}
              onClose={handleSecondaryAction}
            />
          )}
        </>
      )}

      {isCreateOrEdit && (
        <>
          {isDesktop && (
            <Divider
              direction="vertical"
              className="signup-sheet__header__divider"
              height="calc(100% - 0.5rem)"
            />
          )}
          <SheetOptions trackChangeUpstream={trackChangeUpstream} />
        </>
      )}

      {showVolunteerFlowActions && (
        <>
          <Button
            data-qa-id="get-shareable-link"
            className="signup-sheet__header__action-button"
            variant="brand-primary"
            onClick={handleShare}
            icon={copied ? <CheckmarkIcon ratio={0.5} /> : null}
            disableProcessing
          >
            {copied ? 'Copied!' : 'Copy link'} <LinkIcon ratio={0.6} color={white} />
          </Button>
          <SheetOptions onCopyShareableLink={handleShare} onGetPrintableSheet={handlePrint} />
        </>
      )}
    </div>
  );
};

HeaderActionButtons.propTypes = {
  handleShare: PropTypes.func.isRequired,
  handlePrint: PropTypes.func,
  copied: PropTypes.bool,
};
