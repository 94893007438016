import {INIT_APP, VOLUNTEER_SIGNUP} from '~/common/fabric/constants';
import {USER_LOGGED_IN} from '~library/organisms/LoginModal/reducer';

const initialState = {
  userId: '',
  userName: '',
  subscriptionPlanName: '',
  subscriptionStatus: '',
  subscriptionEndDate: '',
  email: '',
  emailMd5: '',
  emailSha256: '',
  avatarDisk: '',
  imgUrl: '',
  initials: '',
  firstName: '',
  is_authenticated: false,
  phoneNumber: '',
  volunteerEmail: '',
  volunteerName: '',
};

export function reducer(state = initialState, action = {}) {
  const {user} = action?.payload || {};
  switch (action.type) {
    case INIT_APP: {
      if (!user) return state;
      return {
        ...state,
        userId: user.userId,
        userName: user.userName,
        subscriptionPlanName:
          window.client_data?.subscription_plan_name || user.subscriptionPlanName,
        subscriptionEndDate: window.client_data?.subscription_end_date || user.subscriptionEndDate,
        subscriptionStatus: window.client_data?.subscription_status || user.subscriptionStatus,
        email: user.email,
        emailMd5: window.client_data?.evc_h,
        emailSha256: window.client_data?.evc_s256l,
        avatarDisk: user.avatarDisk,
        imgUrl: user.imgUrl,
        initials: user.initials,
        firstName: user.firstName,
        is_authenticated: user.isAuthenticated,
        phoneNumber: window.user_phone_number,
      };
    }
    case USER_LOGGED_IN:
      return {
        ...state,
        userId: action.userId,
        userName: action.userName,
        subscriptionPlanName: action.subscriptionPlanName,
        subscriptionEndDate: action.subscriptionEndDate,
        subscriptionStatus: action.subscriptionStatus,
        email: action.email,
        emailMd5: action.emailMd5,
        emailSha256: action.emailSha256,
        avatarDisk: action.avatarDisk,
        imgUrl: action.imgUrl,
        initials: action.initials,
        firstName: action.firstName,
        phoneNumber: action.user_phone_number,
        is_authenticated: true,
      };
    case VOLUNTEER_SIGNUP:
      return {
        ...state,
        volunteerEmail: action.payload?.volunteerEmail,
        volunteerName: action.payload?.volunteerName,
      };
    default:
      return state;
  }
}
