import {useCallback} from 'react';
import {LinkIcon} from '~library/svg/LinkIcon';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetOption} from '~/SignUpSheets/components/Header/components/SheetOptions/components/SheetOption/SheetOption';

export const CopyShareableLink = () => {
  const {onCopyShareableLink, closeOptions} = useSheetOptions();

  const onCopy = useCallback(() => {
    onCopyShareableLink();
    closeOptions();
  }, [onCopyShareableLink, closeOptions]);

  return (
    <SheetOption title="Copy shareable link" icon={<LinkIcon ratio={0.75} />} onClick={onCopy} />
  );
};
