import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button} from '~library/atoms/Button';
import {selectFieldSchema} from '~/SignUpSheets/reducers/blueprint/selectors';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {SCOPE_HOST} from '~/SignUpSheets/reducers/sheet/constants';
import {schemaToJson} from '~/SignUpSheets/utils/schema';
import {ConfirmDeleteSlotsModal} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/ConfirmDeleteSlotsModal';
import {OptionGroup} from '~/SignUpSheets/components/Header/components/SheetOptions/components/OptionGroup/OptionGroup';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetOption} from '~/SignUpSheets/components/Header/components/SheetOptions/components/SheetOption/SheetOption';

export const DeleteSlots = () => {
  const {updateSheetDetails} = useUpdateSheet();
  const {closeOptions} = useSheetOptions();
  const fieldSchema = useSelector(selectFieldSchema);
  const [showDeleteSlotsModal, setShowDeleteSlotsModal] = useState(false);

  const openDeleteSlotsModal = useCallback(() => {
    setShowDeleteSlotsModal(true);
  }, []);

  const closeDeleteSlotsModal = useCallback(() => {
    setShowDeleteSlotsModal(false);
    closeOptions();
  }, [closeOptions]);

  const onDeleteSlots = useCallback(() => {
    window.dataLayer.push({event: 'delete-all-clicked-start-over-modal'});
    const newSignupOptionsList = [schemaToJson(fieldSchema, 'datetime', SCOPE_HOST)];
    updateSheetDetails({payload: {signup_options: newSignupOptionsList, signups: []}});
    closeDeleteSlotsModal();
    closeOptions();
    window.dataLayer.push({event: 'success-slots-deleted-start-over-modal'});
  }, [fieldSchema, updateSheetDetails, closeDeleteSlotsModal, closeOptions]);

  return (
    <>
      <OptionGroup title="Delete Slots">
        <SheetOption title="Delete all slots">
          <Button
            variant="secondary"
            size="large"
            data-qa-id="delete-slots-button"
            onClick={openDeleteSlotsModal}
          >
            Delete slots
          </Button>
        </SheetOption>
      </OptionGroup>

      {showDeleteSlotsModal && (
        <ConfirmDeleteSlotsModal onClose={closeDeleteSlotsModal} onSubmit={onDeleteSlots} />
      )}
    </>
  );
};
