import {Typography} from '~library/atoms/Typography';
import {CloseLineIcon} from '~library/svg/CloseLineIcon';

export const MenuHeader = ({
  toggleMenu,
  variant = 'paragraph4',
  color = 'pebble',
  semibold = false,
  size = 'medium',
  title,
  uppercase = true,
}) => (
  <div className="menu-content__header">
    <div className="menu-content__title">
      <Typography as="h1" variant={variant} size={size} color={color} semibold={semibold}>
        {uppercase ? title.toUpperCase() : title}
      </Typography>
    </div>
    <button
      className="menu-content__close-btn unstyled-button"
      onClick={toggleMenu}
      data-qa-id="slot-toolbar-menu-mobile-close"
      data-impression-tracking="true"
      aria-label="Close menu"
      type="button"
    >
      <CloseLineIcon ratio={0.75} />
    </button>
  </div>
);
