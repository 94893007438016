import {useSelector} from 'react-redux';
import {selectIsDateTimeBlueprint} from '~/SignUpSheets/reducers/blueprint/selectors';
import {selectHasSignups} from '~/SignUpSheets/reducers/sheet/selectors';
import {PrivacyAndQuestionsOptions} from './PrivacyAndQuestionsOptions';
import {AdvancedOptions} from './buttons/AdvancedOptions';
import {DeleteSlots} from './buttons/DeleteSlots';

export const SheetSettings = () => {
  const isDatetimeType = useSelector(selectIsDateTimeBlueprint);
  const hasSignups = useSelector(selectHasSignups);

  return (
    <div className="sheet-options">
      <PrivacyAndQuestionsOptions />
      {isDatetimeType && (
        <>
          <AdvancedOptions />
          {!hasSignups && <DeleteSlots />}
        </>
      )}
    </div>
  );
};
