import cx from 'classnames';
import {useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {getLoggedUserId} from '~/common/fabric/User/reducers/user';
import {usePrevious} from '~/common/hooks/usePrevious';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';
import {Button} from '~library/atoms/Button';
import {Typography} from '~library/atoms/Typography';
import {SettingsFillIcon} from '~library/svg/SettingsFillIcon';
import {charcoal} from '~libSass/base/_exports.sass';
import {FLOW_STATES} from '~/SignUpSheets/constants';
import {SheetOptionsMenu} from './components/SheetOptionsMenu';
import {SheetOptionsProvider, useSheetOptions} from './context/SheetOptionsContext';

import './SheetOptions.sass';

const SheetOptionsContent = () => {
  const {flow} = useContext(SignUpSheetContext);
  const isLoggedIn = useSelector(getLoggedUserId);
  const {optionsOpen, openOptions, closeOptions} = useSheetOptions();
  const isCreateFlow = flow === FLOW_STATES.CREATE;
  const isViewFlow = flow === FLOW_STATES.VIEW;
  const title = isViewFlow ? 'Organizer Tools' : 'Sheet Settings';
  const {onSave} = useSheetActions();
  const previousOptionsOpenValue = usePrevious(optionsOpen);
  const sheetId = useSelector(selectSheetId);

  useEffect(() => {
    document.body.style.overflow = optionsOpen ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [optionsOpen]);

  useEffect(() => {
    if (!sheetId) {
      return;
    }

    // when the page loads the flow flag is always edit, after some point it changes to the correct flow
    // this is why it was necessary to add this into a useEffect
    if (isCreateFlow) {
      closeOptions();
    } else {
      openOptions();
    }
  }, [isCreateFlow, openOptions, closeOptions, sheetId]);

  // this will save the sheet when the options are closed
  useEffect(() => {
    if (previousOptionsOpenValue === true && optionsOpen === false && sheetId) {
      onSave();
    }
  }, [optionsOpen, previousOptionsOpenValue, onSave, sheetId]);

  return (
    <>
      <Button
        variant="transparent"
        className={cx('signup-sheet__header__more-menu', {
          'signup-sheet__header__more-menu--styled': isViewFlow,
        })}
        onClick={openOptions}
        aria-label="Options"
        disabled={!isLoggedIn}
        data-qa-id="sheet-options-trigger"
      >
        <SettingsFillIcon ratio={0.5} color={charcoal} />
        <Typography variant="paragraph2">{title}</Typography>
      </Button>

      <SheetOptionsMenu title={title} />
    </>
  );
};

export const SheetOptions = ({trackChangeUpstream, onCopyShareableLink, onGetPrintableSheet}) => (
  <SheetOptionsProvider
    trackChangeUpstream={trackChangeUpstream}
    onCopyShareableLink={onCopyShareableLink}
    onGetPrintableSheet={onGetPrintableSheet}
  >
    <SheetOptionsContent />
  </SheetOptionsProvider>
);
