import {useSelector} from 'react-redux';
import {Typography} from '~library/atoms/Typography';
import {selectSignupQuestions} from '~/SignUpSheets/reducers/sheet/selectors';

import './PrintableSlot.sass';

const getGroupedSlotTitle = (group) => {
  if (!group) return null;
  switch (group.group_type) {
    case 'time': {
      return `${group.start_time} - ${group.end_time}`;
    }
    default: {
      return null;
    }
  }
};

const getTitle = (slot) => {
  if (slot.group) {
    return getGroupedSlotTitle(slot.group);
  }
  if (slot.start_time && slot.end_time && !slot.all_day) {
    return `${slot.start_time} - ${slot.end_time}`;
  }
  if (slot.item) return slot.item;
  return slot.title;
};

const getSubtitle = (slot) => {
  if (slot.item) return {content: slot.item, rich: false};
  if (slot.group) return {content: slot.item ?? slot.title, rich: false};
  if (slot.all_day) return {content: slot.description, rich: true};
  return {content: slot.title ?? slot.description, rich: !slot.title && !!slot.description};
};

const getDescription = (slot) => {
  if (slot.group) return slot.description;
  if (slot.all_day) return null;
  if (slot.item || slot.title) return slot.description;
  return null;
};

export const PrintableSlot = ({
  slot = {
    group: null,
    item: null,
    title: null,
    start_time: null,
    end_time: null,
    all_day: false,
    description: '',
    product_link: null,
    signups: [],
  },
  isDatetimeType,
}) => {
  const title = isDatetimeType ? null : getTitle(slot);
  const subtitle = getSubtitle(slot);
  const description = getDescription(slot);
  const signupQuestions = useSelector(selectSignupQuestions);
  const colWidth = `${(40 / signupQuestions.length).toString()}%`;

  return (
    <div className="printable-slot">
      <table>
        <thead className="printable-slot__details-wrapper">
          {!isDatetimeType && title && (
            <tr>
              <th className="printable-slot__no-padding">
                <div className="printable-slot__title-info">
                  <Typography
                    variant="paragraph3"
                    className="printable-slot__title-info__title"
                    semibold
                  >
                    {title}
                  </Typography>
                  {!slot.group && (
                    <Typography
                      variant="paragraph4"
                      className="printable-slot__title-info__description"
                    >
                      {description}
                    </Typography>
                  )}
                </div>
              </th>
            </tr>
          )}
          {(isDatetimeType || slot.group) && (
            <tr>
              <th className="printable-slot__details">
                {slot?.start_time && (
                  <Typography
                    variant="paragraph4"
                    color="charcoal"
                    semibold
                    className="printable-slot__details__col-1 signup-sheet__rendered-rich-text"
                  >
                    {`${slot.start_time} - ${slot.end_time}`}
                  </Typography>
                )}
                {subtitle.content && (
                  <Typography
                    variant="paragraph4"
                    color="charcoal"
                    semibold
                    className="printable-slot__details__col-2 signup-sheet__rendered-rich-text"
                  >
                    {subtitle.content}
                  </Typography>
                )}
                {description && (
                  <Typography
                    variant="paragraph4"
                    color="asphalt"
                    className="printable-slot__details__col-3 signup-sheet__rendered-rich-text"
                  >
                    {description}
                  </Typography>
                )}
              </th>
            </tr>
          )}
        </thead>
      </table>
      <table className="printable-slot__filled-slots">
        <colgroup>
          <col width="2%" />
          <col width="12%" />
          <col width="18%" />
          {signupQuestions.map((q) => (
            <col key={q.uuid} width={colWidth} />
          ))}
          <col width="28%" />
        </colgroup>
        <thead>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
            <th className="printable-slot__details-col-head">
              <Typography variant="paragraph3" color="coin">
                Name
              </Typography>
            </th>
            <th className="printable-slot__details-col-head">
              <Typography variant="paragraph3" color="coin">
                Email Address
              </Typography>
            </th>
            {signupQuestions.map((q) => (
              <th key={q.uuid} className="printable-slot__details-col-head">
                <Typography variant="paragraph3" color="coin">
                  {q.title || ''}
                </Typography>
              </th>
            ))}
            <th className="printable-slot__details-col-head">
              <Typography variant="paragraph3" color="coin">
                Comment
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {slot.signups
            .sort((a, b) => a.name - b.name)
            .map((signup, i) => (
              <tr key={signup.id}>
                <td>
                  <Typography variant="paragraph4" color="asphalt">
                    {`${i + 1}.`}
                  </Typography>
                </td>
                <td className="printable-slot__details-cell">
                  <Typography variant="paragraph4" color="asphalt">
                    {signup.name || '--'}
                  </Typography>
                </td>
                <td className="printable-slot__details-cell">
                  <Typography variant="paragraph4" color="asphalt">
                    {signup.email || '--'}
                  </Typography>
                </td>
                {signupQuestions.map((q) => {
                  const answer = signup.answers.find((a) => a.question_id === q.uuid);
                  return (
                    <td key={q.uuid} className="printable-slot__details-cell">
                      <Typography variant="paragraph4" color="asphalt">
                        {answer?.answer || '--'}
                      </Typography>
                    </td>
                  );
                })}
                <td className="printable-slot__details-cell">
                  <Typography variant="paragraph4" color="asphalt">
                    {signup.comment || '--'}
                  </Typography>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
