import {useCallback, useContext, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import {pluralize} from '~/common/utils';
import {selectUser} from '~/common/fabric/User/reducers/user';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {SET_SELECTED_SIGNUPS, SET_SIGNUPS} from '~/SignUpSheets/reducers/sheet/constants';
import {
  selectAnswersBySlotIdAndName,
  selectExpired,
  selectIsOrganizer,
  selectPrivacyOptions,
  selectSheetStatus,
  selectSignupQuestions,
  selectSignups,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {useRemoveSignup} from '~/SignUpSheets/hooks/useRemoveSignup';
import {Typography} from '~library/atoms/Typography';
import {Button} from '~library/atoms/Button';
import {TrashIcon} from '~library/svg/TrashIcon';
import {SmsLineIcon} from '~library/svg/SmsLineIcon';
import {InboxIcon} from '~library/svg/InboxIcon';
import {ResponsiveModal} from '~library/organisms/ResponsiveModal';
import {AccountLineIcon} from '~library/svg/AccountLineIcon';
import {MealOptionsSmIcon} from '~library/svg/MealOptionsSmIcon';
import {MobileDeviceIcon} from '~library/svg/MobileDeviceIcon';

import {pebble} from '~libSass/base/_exports.sass';
import './FilledSlot.sass';

const FilledVolunteerDetail = ({icon, label, text, hide}) =>
  !hide &&
  text?.trim()?.length > 0 && (
    <div className="filled-slot__item__detail">
      {icon && icon}
      <Typography
        variant="list1"
        color="pebble"
        data-qa-id={`signup-row-${label}`}
        data-qa-value={text}
      >
        {text}
      </Typography>
    </div>
  );

const PrivateInfoWarning = () => (
  <Typography
    className="filled-slot__visibility-disclaimer"
    variant="label2"
    size="smaller"
    color="pebble"
  >
    Only visible to you and the organizer
  </Typography>
);

export const FilledSlot = ({
  userId,
  name,
  comment,
  email,
  phone,
  signupsCount,
  matchingUser,
  slotId,
  signUpOptionId,
}) => {
  const [showConfirmDeleteVolunteer, setShowConfirmDeleteVolunteer] = useState(false);
  const {flow} = useContext(SignUpSheetContext);
  const isOrganizer = useSelector(selectIsOrganizer);
  const signups = useSelector(selectSignups);
  const questions = useSelector(selectSignupQuestions);
  const answers = useSelector(selectAnswersBySlotIdAndName(slotId, name));
  const dispatch = useDispatch();
  const [handleRemoveSignup] = useRemoveSignup();
  const past = useSelector((s) => selectExpired(s, {uuid: slotId || signUpOptionId}));
  const status = useSelector(selectSheetStatus);
  const {hide_names: privateNames, hide_comments: privateComments} =
    useSelector(selectPrivacyOptions);
  const user = useSelector(selectUser);

  const isOrganizerOrMatchingUser = isOrganizer || matchingUser;

  const canceled = status === 'canceled';
  const expired = useMemo(() => past || canceled, [past, canceled]);

  const filledSlotAnswers = useMemo(
    () =>
      answers.map((a) => {
        let {answer} = a;
        let icon = null;
        const {label} = questions.find((q) => q.uuid === a.question_id);
        if (label === 'children_names') {
          answer = `Children: ${a.answer}`;
          icon = <AccountLineIcon color={pebble} ratio={0.5} />;
        } else if (label === 'allergies') {
          answer = `Allergies: ${a.answer}`;
          icon = <MealOptionsSmIcon color={pebble} ratio={0.5} />;
        } else if (label === 'potluck_item') {
          answer = `Potluck: ${a.answer}`;
          icon = <MealOptionsSmIcon color={pebble} ratio={0.5} />;
        }
        return {...a, answer, icon};
      }),
    [answers, questions]
  );

  const handleTrashCanClick = useCallback(() => {
    const updatedSignups = signups.map((su) => {
      let isDeleted = false;
      let slotToDelete = null;
      const matchingUserId = su.user_id === userId;

      if (su.slot_id) {
        slotToDelete = su.slot_id === slotId && matchingUserId ? su : null;
      } else if (su.signup_option) {
        slotToDelete = su.signup_option === signUpOptionId && matchingUserId ? su : null;
      }

      if (slotToDelete) {
        if (slotToDelete.id) {
          // If it contains an ID, it's a saved signup, so remove it from the backend
          handleRemoveSignup(slotToDelete);
        }
        isDeleted = true;
      }

      return {...su, deleted: isDeleted};
    });

    const signupsToKeep = updatedSignups.filter((su) => !su.deleted);

    dispatch({type: SET_SIGNUPS, payload: signupsToKeep});
    dispatch({type: SET_SELECTED_SIGNUPS, payload: signupsToKeep});
  }, [handleRemoveSignup, dispatch, signups, userId, slotId, signUpOptionId]);

  return (
    <div className="filled-slot__container">
      <div className={cx('filled-slot__item', {'filled-slot__item--own': matchingUser})}>
        <div
          className="filled-slot__name-comment-wrapper"
          data-qa-id="signup-row-user"
          data-qa-value={name}
        >
          {!isOrganizer && matchingUser && privateNames && privateComments && (
            <PrivateInfoWarning />
          )}

          <div className="filled-slot__name-row">
            <div>
              <Typography variant="list2" size="large" as="span" data-qa-id="signup-row-name">
                {(privateNames && !isOrganizerOrMatchingUser) || !name ? 'Volunteer' : name}
              </Typography>
              {matchingUser && (
                <Typography variant="paragraph2" as="span">
                  {' (you)'}
                </Typography>
              )}
            </div>

            <div className="filled-slot__count-and-delete">
              <Typography color="charcoal" variant="list1" size="small">
                {pluralize('slot', signupsCount)}
              </Typography>
              {flow === 'edit' && isOrganizer && !expired ? (
                <Button
                  variant="transparent"
                  data-qa-id="filled-slot__delete-btn"
                  onClick={setShowConfirmDeleteVolunteer}
                  aria-label={`Delete ${pluralize('signup', signupsCount)} for ${name}`}
                  className="filled-slot__delete-btn"
                >
                  <TrashIcon color={pebble} />
                </Button>
              ) : null}
            </div>
          </div>

          {!isOrganizer && matchingUser && !privateNames && privateComments && (
            <PrivateInfoWarning />
          )}

          <FilledVolunteerDetail
            label="comment"
            text={comment}
            icon={<SmsLineIcon color={pebble} ratio={0.5} />}
            hide={privateComments && !isOrganizerOrMatchingUser}
          />

          {answers.length > 0 && !isOrganizer && matchingUser && !privateComments && (
            <PrivateInfoWarning />
          )}

          <FilledVolunteerDetail
            label="email"
            text={email || (matchingUser && user.email)}
            icon={<InboxIcon color={pebble} ratio={0.5} />}
            hide={!isOrganizerOrMatchingUser}
          />

          <FilledVolunteerDetail
            label="phone"
            text={phone}
            icon={<MobileDeviceIcon color={pebble} ratio={0.5} />}
            hide={!isOrganizerOrMatchingUser}
          />

          {filledSlotAnswers.map((a) => (
            <FilledVolunteerDetail
              key={a.question_id}
              label={a.label}
              text={a.answer}
              icon={a.icon}
              hide={!isOrganizerOrMatchingUser}
            />
          ))}
        </div>
      </div>
      {showConfirmDeleteVolunteer && (
        <ResponsiveModal
          isOpen
          title={`Are you sure you want to remove ${name} from this slot?`}
          primaryText="Yes, remove them"
          primaryAction={handleTrashCanClick}
          secondaryText="No, don't remove them"
          secondaryAction={() => {
            setShowConfirmDeleteVolunteer(false);
          }}
          onClose={() => setShowConfirmDeleteVolunteer(false)}
          body={`Once you remove ${name}, we’ll notify them via email.`}
          equalButtons
        />
      )}
    </div>
  );
};
