/* eslint-disable max-lines-per-function */
import {useCallback, useState} from 'react';
import {shallowEqual, useDispatch, useSelector, useStore} from 'react-redux';
import {v4} from 'uuid';
import {toastAlert} from '~library/atoms/Toast';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {
  DELETE_GROUP,
  DELETE_SIGNUP_OPTION,
  DUPLICATE_GROUP,
  MOVE_SIGNUP_OPTION_DOWN,
  MOVE_SIGNUP_OPTION_UP,
  SCOPE_HOST,
  UPDATE_ERRORS,
  UPDATE_GROUP,
  UPDATE_SHEET_DETAILS,
} from '~/SignUpSheets/reducers/sheet/constants';
import {
  selectErrors,
  selectNumSignupOptions,
  selectNumSignupSlots,
  selectSheetId,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {isValidDaysNumber, isValidSlotsNumber} from '~/SignUpSheets/utils/validation';
import {useAffiliateActions} from './useAffiliateActions';
import {entryHasProductLink, matchPrevOptionAndIncrementDay} from '~/SignUpSheets/utils/misc';
import {duplicateSlot, schemaToJson} from '~/SignUpSheets/utils/schema';
import {selectBlueprintType, selectFieldSchema} from '~/SignUpSheets/reducers/blueprint/selectors';

export const useUpdateSheet = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const fieldSchema = useSelector(selectFieldSchema, shallowEqual);
  const blueprintType = useSelector(selectBlueprintType);
  const sheetId = useSelector(selectSheetId);
  const numSignupOptions = useSelector(selectNumSignupOptions);
  const numSignupSlots = useSelector(selectNumSignupSlots);
  const errors = useSelector(selectErrors, shallowEqual);
  const {autoSave, isSavingSheet} = useSheetActions();
  const {processProductLinks} = useAffiliateActions();
  const [tryAutoSaveOnce, setTryAutoSaveOnce] = useState(false);

  const validateSheet = useCallback(
    (payload = {signup_options: []}, appendIfArray = false) => {
      const slots = payload.signup_options?.flatMap((so) => so.slots) ?? [];
      const days = payload.signup_options ?? [];

      const newNumSignupOptions = appendIfArray ? numSignupOptions + days.length : days.length;
      const newNumSignupSlots = appendIfArray ? numSignupSlots + slots.length : slots.length;

      const validSlotsNumber = isValidSlotsNumber(newNumSignupSlots);
      const validDaysNumber = isValidDaysNumber(newNumSignupOptions);

      const sheetErrors = [validSlotsNumber, validDaysNumber]
        .filter(({isValid}) => !isValid)
        .map(({message}) => message);
      if (sheetErrors.length > 0) {
        sheetErrors.map((error) =>
          toastAlert(error, {
            type: 'error',
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
        throw new Error('Sheet validation failed');
      }
    },
    [numSignupOptions, numSignupSlots]
  );

  const updateSheetDetails = useCallback(
    ({payload, appendIfArray = false, saveChanges = false, errorsToClear = []}) => {
      validateSheet(payload, appendIfArray);

      dispatch({
        type: UPDATE_SHEET_DETAILS,
        payload: {
          pristine: false,
          appendIfArray,
          ...payload,
        },
      });

      // TODO: If we're going to refactor this to allow changing multiple fields at once, we will have to refactor this section
      // since this if statement and the wishlist error handler in the Redux store
      // is built on the assumption that only one field is being updated at a time
      if (!('has_unsaved_wishlists' in payload)) {
        const errorMessages = [...errors];
        errorsToClear.forEach((loc) => {
          const idx = errorMessages.findIndex((e) => e.loc.every((l, i) => l === loc?.[i]));
          if (idx >= 0) errorMessages.splice(idx, 1);
        });
        dispatch({
          type: UPDATE_ERRORS,
          payload: {errors: errorMessages},
        });
      }
      if (!sheetId && saveChanges && !isSavingSheet && !tryAutoSaveOnce) {
        // Auto save the first time any Builder or Wishlist changes are made
        autoSave().catch((e) => evite.error('Failed to save sheet', e));
        setTryAutoSaveOnce(true);
      }
    },
    [sheetId, autoSave, isSavingSheet, errors, tryAutoSaveOnce, validateSheet]
  );

  const updateGroup = useCallback(
    (groupId, details, saveChanges = true) => {
      dispatch({
        type: UPDATE_GROUP,
        payload: {
          groupId,
          details,
        },
      });
      if (!sheetId && saveChanges && !isSavingSheet && !tryAutoSaveOnce) {
        // Auto save the first time any Builder or Wishlist changes are made
        autoSave().catch((e) => evite.error('Failed to save sheet', e));
        setTryAutoSaveOnce(true);
      }
    },
    [sheetId, autoSave, isSavingSheet, tryAutoSaveOnce]
  );

  const deleteSignupOption = useCallback(
    (index, groupId, trackChangeUpstream) => {
      if (!groupId) {
        dispatch({
          type: DELETE_SIGNUP_OPTION,
          payload: {index},
        });
      } else {
        const signupOptionsInGroup = store
          .getState()
          .sheet.signup_options.map((so, i) => ({
            ...so,
            index: i,
          }))
          .filter((so) => so.group === groupId);
        const signupOptionInGroup = signupOptionsInGroup[index];
        if (!signupOptionInGroup) {
          evite.error('Invalid signup option index');
          return;
        }
        if (groupId && signupOptionsInGroup.length > 1) {
          trackChangeUpstream?.([groupId]);
        }
        dispatch({
          type: DELETE_SIGNUP_OPTION,
          payload: {index: signupOptionInGroup.index},
        });
      }
    },
    [store]
  );

  const addAnotherSignupOption = useCallback(
    (groupId, trackChangeUpstream) => {
      const slotSchema = fieldSchema.find((i) => i.name === 'slots');
      const storeSignupOptions = store.getState().sheet.signup_options;
      const newSignupOption = matchPrevOptionAndIncrementDay(
        [
          ...storeSignupOptions,
          schemaToJson(fieldSchema, blueprintType, SCOPE_HOST, undefined, undefined, groupId),
        ],
        slotSchema
      );
      if (groupId) {
        trackChangeUpstream?.([groupId]);
        updateGroup(groupId, {group_type: 'time'}, false); // NEXT: Expand to generic "type" value (i.e. not only "time")
      } else {
        trackChangeUpstream?.([newSignupOption.uuid]);
      }
      updateSheetDetails({payload: {signup_options: [newSignupOption]}, appendIfArray: true});
    },
    [fieldSchema, blueprintType, updateSheetDetails, store, updateGroup]
  );

  const duplicateSignupOption = useCallback(
    (index, groupId, trackChangeUpstream) => {
      const signupOptions = store.getState().sheet.signup_options.map((so, i) => ({
        ...so,
        index: i,
      }));
      const soListForDup = groupId
        ? signupOptions.filter((so) => so.group === groupId)
        : [...signupOptions];
      if (index < 0 || index >= signupOptions.length) return;

      const item = {
        ...soListForDup[index],
        uuid: v4(),
        new: true,
      };
      trackChangeUpstream?.([groupId ?? item.uuid]);
      if (item?.slots) {
        item.slots = item.slots.map((s) => duplicateSlot(s));
      }
      signupOptions.splice(soListForDup[index].index + 1, 0, item);

      updateSheetDetails({
        // Remove index from each object in list; it was just for the context of this function
        payload: {signup_options: signupOptions.map(({index: i, ...so}) => so)},
        saveChanges: true,
      });
      if (entryHasProductLink(item) || item.slots?.some((slot) => entryHasProductLink(slot))) {
        processProductLinks();
      }
    },
    [store, updateSheetDetails, processProductLinks]
  );

  const moveSignupOptionUp = useCallback(
    (groupId) => (index) => {
      dispatch({
        type: MOVE_SIGNUP_OPTION_UP,
        payload: {index, groupId},
      });
    },
    []
  );

  const moveSignupOptionDown = useCallback(
    (groupId) => (index) => {
      dispatch({
        type: MOVE_SIGNUP_OPTION_DOWN,
        payload: {index, groupId},
      });
    },
    []
  );

  const copySlotsToEmptyDates = useCallback(
    (index, trackChangeUpstream) => {
      const signupOptions = store.getState().sheet.signup_options;
      if (index < 0 || index >= signupOptions.length) return;
      const item = signupOptions[index];
      if (!item.slots) return;

      // Copy slots to date where slots are empty
      const newSignupOptionsList = signupOptions.map((option) => {
        if (option.uuid === item.uuid) return option;
        return {
          ...option,
          slots: [
            // "item" is the copy source, "option" is the paste target
            ...item.slots.map((s) => duplicateSlot(s, item.all_day, option.all_day, true)),
            ...(option.slots ?? []),
          ],
        };
      });
      trackChangeUpstream?.(newSignupOptionsList.map((so) => so.uuid));
      updateSheetDetails({
        payload: {signup_options: newSignupOptionsList},
        saveChanges: true,
      });
      if (item.slots?.some((slot) => entryHasProductLink(slot))) {
        processProductLinks();
      }
    },
    [store, updateSheetDetails, processProductLinks]
  );

  const duplicateGroup = useCallback((groupId, trackChangeUpstream) => {
    const newGroupId = v4();
    trackChangeUpstream?.([newGroupId]);
    dispatch({
      type: DUPLICATE_GROUP,
      payload: {groupId, newGroupId},
    });
  }, []);

  const deleteGroup = useCallback((groupId) => {
    dispatch({
      type: DELETE_GROUP,
      payload: {groupId},
    });
  }, []);

  return {
    updateSheetDetails,
    updateGroup,
    deleteSignupOption,
    addAnotherSignupOption,
    duplicateSignupOption,
    moveSignupOptionUp,
    moveSignupOptionDown,
    copySlotsToEmptyDates,
    duplicateGroup,
    deleteGroup,
  };
};
