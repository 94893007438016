import {Checkbox} from '~library/atoms/Checkbox';
import {Typography} from '~library/atoms/Typography';

import './VolunteerItem.sass';

export const VolunteerItem = ({volunteer, isSelected, onSelect}) => (
  <div className="message-volunteers__volunteer-item">
    <Checkbox
      checked={isSelected}
      onChange={onSelect}
      data-qa-id={`message-volunteers-volunteer-checkbox-${volunteer.email}`}
      blueCheckbox
    />
    <Typography variant="paragraph2" className="message-volunteers__volunteer-item__name">
      {volunteer.name}
    </Typography>
  </div>
);
