import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetOption} from '~/SignUpSheets/components/Header/components/SheetOptions/components/SheetOption/SheetOption';
import {MessageVolunteers} from '~/SignUpSheets/components/Header/components/SheetOptions/MessageVolunteers/MessageVolunteers';
import {MessageLineIcon} from '~library/svg/MessageLineIcon';
import {selectNumSignups} from '~/SignUpSheets/reducers/sheet/selectors';

export const MessageVolunteersButton = () => {
  const numSignups = useSelector(selectNumSignups);
  const hasSignups = numSignups > 0;
  const {closeOptions} = useSheetOptions();
  const [showMessageVolunteersModal, setShowMessageVolunteersModal] = useState(false);

  const openMessageVolunteers = useCallback(() => {
    setShowMessageVolunteersModal(true);
  }, []);

  const closeMessageVolunteers = useCallback(() => {
    setShowMessageVolunteersModal(false);
    closeOptions();
  }, [closeOptions]);

  if (!hasSignups) {
    return null;
  }

  return (
    <>
      <SheetOption
        title={
          <span>
            Message Volunteers -<span className="link"> New!</span>
          </span>
        }
        description="Message individuals or groups of volunteers in a few easy steps."
        icon={<MessageLineIcon ratio={0.5} />}
        onClick={openMessageVolunteers}
      />

      {showMessageVolunteersModal && (
        <MessageVolunteers visible={showMessageVolunteersModal} onClose={closeMessageVolunteers} />
      )}
    </>
  );
};
