import {useCallback, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  selectHasSignups,
  selectSettings,
  selectSheetId,
  selectSheetType,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {OptionGroup} from './OptionGroup/OptionGroup';
import {SheetOptionWithToggle} from './SheetOption/SheetOptionWithToggle';
import {UPDATE_SHEET_SETTINGS} from '~/SignUpSheets/reducers/sheet/constants';

const CONFIG_FIELDS = {
  SHOW_IN_SIGNUP: 'show_in_signup',
  IS_REQUIRED: 'is_required',
};

const PRIVACY_FIELDS = {
  HIDE_NAMES: 'hide_names',
  HIDE_COMMENTS: 'hide_comments',
};

export const PrivacyAndQuestionsOptions = () => {
  const settings = useSelector(selectSettings, shallowEqual);
  const sheetType = useSelector(selectSheetType);
  const sheetId = useSelector(selectSheetId);
  const hasSignups = useSelector(selectHasSignups);
  const dispatch = useDispatch();

  const updateSheetSettings = useCallback(
    (newSettings) => {
      dispatch({
        type: UPDATE_SHEET_SETTINGS,
        payload: newSettings,
      });
    },
    [dispatch]
  );

  const togglePhoneConfig = useCallback(
    (field) => () => {
      const newPhoneConfig = {
        ...settings.phone_config,
        [field]: !settings.phone_config[field],
      };

      updateSheetSettings({
        phone_config: newPhoneConfig,
      });
    },
    [settings, updateSheetSettings]
  );

  const togglePrivacy = useCallback(
    (field) => () => {
      const newPrivacy = {
        ...settings.privacy,
        [field]: !settings.privacy[field],
      };

      updateSheetSettings({
        privacy: newPrivacy,
      });
    },
    [settings, updateSheetSettings]
  );

  const toggleQuestion = useCallback(
    (questionLabel) => () => {
      const newQuestions = settings.questions.map((question) =>
        question.label === questionLabel
          ? {...question, display_on_signup: !question.display_on_signup}
          : question
      );

      updateSheetSettings({
        questions: newQuestions,
      });
    },
    [settings, updateSheetSettings]
  );

  const toggleQuestionRequired = useCallback(
    (questionLabel) => () => {
      const newQuestions = settings.questions.map((question) =>
        question.label === questionLabel
          ? {...question, is_required: !question.is_required}
          : question
      );

      updateSheetSettings({
        questions: newQuestions,
      });
    },
    [settings, updateSheetSettings]
  );

  const customQuestions = useMemo(
    () =>
      settings?.questions
        ?.filter((question) => question?.applicable_to?.includes(sheetType))
        ?.map((question) => (
          <SheetOptionWithToggle
            key={question.uuid}
            title={`Collect ${question.title}`}
            description={question.organizer_description}
            toggled={question.display_on_signup}
            onToggle={toggleQuestion(question.label)}
            checked={question.is_required}
            onCheck={toggleQuestionRequired(question.label)}
            hideCheckbox={!question.display_on_signup || hasSignups}
            disabled={!sheetId}
          />
        )),
    [settings?.questions, sheetType, toggleQuestion, toggleQuestionRequired, hasSignups, sheetId]
  );

  return (
    <>
      <OptionGroup
        title="Volunteer Management"
        description="Upon signup, volunteers will share their full name email and an optional comment."
      >
        <SheetOptionWithToggle
          title="Collect Phone Number"
          description="Will only be visible to you "
          toggled={settings?.phone_config?.[CONFIG_FIELDS.SHOW_IN_SIGNUP]}
          onToggle={togglePhoneConfig(CONFIG_FIELDS.SHOW_IN_SIGNUP)}
          checked={settings?.phone_config?.[CONFIG_FIELDS.IS_REQUIRED]}
          onCheck={togglePhoneConfig(CONFIG_FIELDS.IS_REQUIRED)}
          hideCheckbox={!settings?.phone_config?.[CONFIG_FIELDS.SHOW_IN_SIGNUP] || hasSignups}
          disabled={!sheetId}
        />

        {customQuestions}
      </OptionGroup>

      <OptionGroup
        title="Privacy"
        description="By default volunteer names and comments are visible to other volunteers."
      >
        <SheetOptionWithToggle
          title="Hide signup names"
          description="Hide the names of signups from other volunteers"
          toggled={settings?.privacy?.[PRIVACY_FIELDS.HIDE_NAMES]}
          onToggle={togglePrivacy(PRIVACY_FIELDS.HIDE_NAMES)}
          hideCheckbox
          disabled={!sheetId}
        />

        <SheetOptionWithToggle
          title="Hide comments"
          description="Hide the comments from other volunteers"
          toggled={settings?.privacy?.[PRIVACY_FIELDS.HIDE_COMMENTS]}
          onToggle={togglePrivacy(PRIVACY_FIELDS.HIDE_COMMENTS)}
          hideCheckbox
          disabled={!sheetId}
        />
      </OptionGroup>
    </>
  );
};
