import cx from 'classnames';
import {Link} from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';
import {Typography} from '~library/atoms/Typography';
import {Button} from '~library/atoms/Button';
import './SheetOption.sass';

export const SheetOption = ({
  title,
  description,
  icon,
  headerAction,
  onClick,
  href,
  children,
  noBorder,
}) => {
  const dataQaId = title ? `sheet-option-${kebabCase(title)}` : '';

  const content = (
    <div className={cx('sheet-option', {'sheet-option--no-border': noBorder})}>
      <div className="sheet-option__header">
        <div className="sheet-option__header__title">
          {icon && icon}
          <Typography variant="paragraph2" semibold>
            {title}
          </Typography>
        </div>

        <div className="sheet-option__header__action">{headerAction}</div>
      </div>

      {description && (
        <Typography variant="paragraph3" color="pebble" className="sheet-option__description">
          {description}
        </Typography>
      )}

      {children && <div className="sheet-option__content">{children}</div>}
    </div>
  );

  if (href) {
    return (
      <Link data-qa-id={dataQaId} as="transparent" href={href} target="_blank">
        {content}
      </Link>
    );
  }

  if (typeof onClick === 'function') {
    return (
      <Button data-qa-id={dataQaId} variant="transparent" disableProcessing onClick={onClick}>
        {content}
      </Button>
    );
  }

  return content;
};
