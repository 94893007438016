import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import {sanitizeString} from '~/common/utils/sanitize_html';
import {ResponsivePanel} from '~library/organisms/ResponsivePanel';
import {Typography} from '~library/atoms/Typography';
import {ResponsiveModal} from '~library/organisms/ResponsiveModal';
import {Button} from '~library/atoms/Button';
import {BottomSheet} from '~library/molecules/BottomSheet';
import {useIsLargishAndUp} from '~library/hooks/useMatchQuery';
import {useLazyGetMessagesQuery} from '~/SignUpSheets/api/sheet';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';

import './SentMessagesMenu.sass';
import './EmailPreviewModal.sass';

const EMAILS = [
  {
    id: '1',
    title: 'Volunteer Signup Confirmation\n(upon signup)',
    img: 'https://g0.evitecdn.com/signup-sheets/product-info/sus-signup-confirmation-email.jpg',
  },
  {
    id: '2',
    title: 'Volunteer Signup Reminder\n(48 hours prior to event start)',
    img: 'https://g0.evitecdn.com/signup-sheets/product-info/sus-reminder-email-48.jpg',
  },
  {
    id: '3',
    title: 'Volunteer Signup Reminder\n(24 hours prior to event start)',
    img: 'https://g0.evitecdn.com/signup-sheets/product-info/sus-reminder-email-24.jpg',
  },
];

const userTimezone = moment.tz.guess();
const HEIGHT_THRESHOLD = 200;

export const SentMessagesMenu = ({visible, onClose}) => {
  const sheetId = useSelector(selectSheetId);
  const isLargishAndUp = useIsLargishAndUp();
  const [getMessages] = useLazyGetMessagesQuery();
  const [messages, setMessages] = useState([]);
  const [emailPreview, setEmailPreview] = useState(null);
  const [minimizedMessages, setMinimizedMessages] = useState({});
  const [messageHeights, setMessageHeights] = useState({});

  useEffect(() => {
    document.body.classList.add('no-overflow');

    return () => {
      document.body.classList.remove('no-overflow');
    };
  }, []);

  useEffect(() => {
    if (!sheetId) return;

    const handleGetMessages = async () => {
      const response = await getMessages(sheetId);
      setMessages(response.data?.data || []);
    };

    handleGetMessages();
  }, [sheetId, getMessages]);

  const handleOpenPreview = useCallback((email) => {
    setEmailPreview(email);
  }, []);

  const handleCloseClick = useCallback(() => {
    setEmailPreview(null);
  }, []);

  const toggleMessageExpansion = useCallback((messageId) => {
    setMinimizedMessages((prev) => ({
      ...prev,
      [messageId]: prev[messageId] !== true,
    }));
  }, []);

  const messageHistory = useMemo(
    () =>
      messages.map((data) => {
        const {id, created_at: createdAt, status, volunteers, message} = data;
        const formattedDate = moment.tz(createdAt, userTimezone).format('MM/DD/YYYY, h:mma');
        const safeStatus = status || 'unknown';
        const isMinimized = minimizedMessages[id] === true;
        const messageHeight = messageHeights[id];
        const needsShowMore = messageHeight > HEIGHT_THRESHOLD;

        return (
          <div
            key={id}
            className="sheet-options__advanced-options sent-messages__message-history__item"
          >
            <Typography
              color="asphalt"
              variant="paragraph4"
              classNames="sent-messages__message-history__status"
            >
              Status: Message {safeStatus[0].toUpperCase() + safeStatus.slice(1)}
            </Typography>
            <Typography variant="paragraph3">{formattedDate}</Typography>
            <div className="sent-messages__message-history__sent-to">
              <Typography variant="paragraph3">Sent to:</Typography>
              <Typography color="pebble" variant="paragraph3">
                {volunteers.map((v) => v.name).join(', ')}
              </Typography>
            </div>
            <Typography variant="paragraph3">Message:</Typography>
            <div
              className={classNames('sent-messages__message-history__message', {
                minimized: isMinimized,
                expanded: !isMinimized && needsShowMore,
              })}
              style={isMinimized ? {maxHeight: `${HEIGHT_THRESHOLD}px`} : {}}
              ref={(el) => {
                if (el && messageHeights[id] === undefined) {
                  setMessageHeights((prev) => ({
                    ...prev,
                    [id]: el.offsetHeight,
                  }));
                  if (el.offsetHeight > HEIGHT_THRESHOLD) {
                    setMinimizedMessages((prev) => ({
                      ...prev,
                      [id]: true,
                    }));
                  }
                }
              }}
            >
              {sanitizeString(message || '', true)}
              {needsShowMore && (
                <div
                  className={classNames('sent-messages__message-history__message-overlay', {
                    'with-background': isMinimized,
                  })}
                >
                  <Button
                    variant="link"
                    onClick={() => toggleMessageExpansion(id)}
                    data-qa-id="show-more-message"
                  >
                    {isMinimized ? 'Show more' : 'Show less'}
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      }),
    [messages, minimizedMessages, messageHeights, toggleMessageExpansion]
  );

  const emailPreviewModal = useMemo(
    () => (
      <ResponsiveModal
        isOpen={emailPreview}
        onClose={handleCloseClick}
        showHeader={false}
        modalId="email-preview-modal"
      >
        <>
          <Typography variant="header5">{emailPreview?.title}</Typography>
          <div className="email-preview-modal__content">
            <img
              className="email-preview-modal__content-img"
              src={emailPreview?.img}
              alt="email preview"
            />
          </div>
          <Button
            fullWidth
            size="large"
            className="email-preview-modal__footer-close-btn"
            data-qa-id="close-email-preview-modal-footer"
            onClick={handleCloseClick}
          >
            Close Preview
          </Button>
        </>
      </ResponsiveModal>
    ),
    [emailPreview, handleCloseClick]
  );

  const content = (
    <div className="sent-messages__content-wrapper">
      {messageHistory.length > 0 && (
        <>
          <Typography
            classNames="sent-messages__message-history__title"
            variant={isLargishAndUp ? 'paragraph1' : 'paragraph2'}
            semibold={!isLargishAndUp}
          >
            Message History
          </Typography>
          {messageHistory}
        </>
      )}
      <Typography
        className="sent-messages__messages-to-send__title"
        variant={isLargishAndUp ? 'paragraph1' : 'paragraph2'}
        semibold={!isLargishAndUp}
      >
        Messages we&apos;ll send on your behalf
      </Typography>
      {EMAILS.map((email) => (
        <div key={email.id} className="sent-messages__messages-to-send__item">
          <Typography variant="paragraph3">{email.title}</Typography>
          <Button
            variant="link"
            onClick={() => handleOpenPreview(email)}
            data-qa-id="view-sample-email"
          >
            View Sample Email
          </Button>
        </div>
      ))}
      {emailPreviewModal}
    </div>
  );

  if (isLargishAndUp) {
    return (
      <ResponsivePanel
        isOpen={visible}
        className="sent-messages__panel"
        onClose={onClose}
        title="Sent Messages"
        modalId="signup-sheet-sent-messages"
        closeQaId="close-signup-sheet-sent-messages"
        showCover
        showBottomNav={false}
        body={content}
      />
    );
  }

  return (
    <>
      {visible && (
        <Button
          className="unstyled-button mobile-menu__overlay"
          onClick={onClose}
          type="button"
          aria-label="Close sent messages overlay"
          variant="transparent"
        />
      )}
      <BottomSheet
        in={visible}
        title="Sent Messages"
        content={content}
        onClose={onClose}
        className="sent-messages__bottom-sheet"
      />
    </>
  );
};
