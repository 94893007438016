import {useSelector} from 'react-redux';
import {SignUpSheetsIcon} from '~library/svg/SignUpSheetsIcon';
import {MessageLineIcon} from '~library/svg/MessageLineIcon';
import {Button} from '~library/atoms/Button';
import {Typography} from '~library/atoms/Typography';
import {Divider} from '~library/atoms/Divider';
import {selectTitle, selectOrganizerName} from '~/SignUpSheets/reducers/sheet/selectors';
import {sanitizeString} from '~/common/utils/sanitize_html';

import {pebble} from '~libSass/base/_exports.sass';
import './FakeMail.sass';

export const FakeMail = ({message}) => {
  const title = useSelector(selectTitle);
  const organizerName = useSelector(selectOrganizerName);

  return (
    <div className="fake-mail">
      <div className="fake-mail__header">
        <SignUpSheetsIcon ratio={0.75} />

        <Divider />
      </div>

      <div className="fake-mail__content">
        <div className="fake-mail__title">
          <Typography variant="header3" semibold>
            You have a new message from
          </Typography>
          <Typography variant="header3">{organizerName}</Typography>
        </div>

        <Typography variant="paragraph2" semibold>
          {title}
        </Typography>

        <MessageLineIcon ratio={0.46} color={pebble} className="fake-mail__message-icon" />

        <div className="fake-mail__message signup-sheet__rendered-rich-text">
          {sanitizeString(message)}
        </div>

        <Button variant="secondary" className="fake-mail__button" disabled>
          View sheet
        </Button>
      </div>

      <div className="fake-mail__footer">
        <Typography variant="paragraph4" color="pebble">
          This email was sent to [recipient name] because you are hosting, or recently hosted, an
          event. Because it is not advertisement, it includes no &rdquo;unsubscribe&rdquo; option.
        </Typography>
        <Typography variant="paragraph4" color="pebble">
          <span className="fake-mail__fake-link">Privacy Policy</span> |{' '}
          <span className="fake-mail__fake-link">Block this Host</span> |{' '}
          <span className="fake-mail__fake-link">Do Not Sell My Info</span>
        </Typography>
      </div>
    </div>
  );
};
