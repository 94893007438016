import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import {AddToCalendarSus} from '~/SignUpSheets/components/AddToCalendar/AddToCalendar';
import {Typography} from '~library/atoms/Typography';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {
  selectGroupingType,
  selectCalendarLinks,
  selectIsPreviewMode,
  selectSheetIsDraft,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectBlueprintType} from '~/SignUpSheets/reducers/blueprint/selectors';
import {useFormattedSheetDate} from '~/SignUpSheets/components/Details/hooks/useFormattedSheetDate';
import {BLUEPRINT_TYPE_ITEMS} from '~/SignUpSheets/reducers/blueprint/constants';
import {SET_SHEET} from '~/SignUpSheets/reducers/sheet/constants';
import {useLazyGetSheetQuery} from '~/SignUpSheets/api/sheet';

export const DetailsWhenViewOnly = () => {
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const dispatch = useDispatch();
  const [getSheet] = useLazyGetSheetQuery();
  const calendarLinks = useSelector(selectCalendarLinks, shallowEqual);
  const blueprintType = useSelector(selectBlueprintType);
  const sheetIsDraft = useSelector(selectSheetIsDraft);
  const isLargishAndUp = useIsLargishAndUp();
  const groupingType = useSelector(selectGroupingType);
  const {date, abbr, timeRange} = useFormattedSheetDate();
  let formattedDate = date;
  const isBluePrintTypeItems = blueprintType === BLUEPRINT_TYPE_ITEMS;

  const updateSheetAfterLogin = async () => {
    const updatedSheet = await getSheet(window.sheet_id);
    dispatch({type: SET_SHEET, payload: updatedSheet.data.data});
  };

  const mustShowOnlyDate =
    groupingType === 'time' &&
    formattedDate &&
    typeof formattedDate === 'string' &&
    (formattedDate.endsWith('PM') || formattedDate.endsWith('AM'));

  if (mustShowOnlyDate) formattedDate = formattedDate.split(' ').slice(0, 4).join(' ');

  return (
    <>
      {(formattedDate || isPreviewMode) && (
        <>
          <Typography variant={isLargishAndUp ? 'paragraph1' : 'paragraph2'}>
            {isPreviewMode && !formattedDate && sheetIsDraft ? '[Sample Date]' : formattedDate}
          </Typography>
          {timeRange}
          {abbr}
        </>
      )}
      {isBluePrintTypeItems && (
        <div className="signup-sheet__view-details__calendar-container">
          <AddToCalendarSus
            isButton
            type="event"
            preview={isPreviewMode}
            className="signup-sheet__view-details__action-btn"
            googleCalUrl={calendarLinks.google_cal_url}
            iCalUrl={calendarLinks.ical_url}
            outlookUrl={calendarLinks.outlook_cal_url}
            buttonSize={isLargishAndUp ? 'large' : 'small'}
            requiresLogin
            afterLogin={updateSheetAfterLogin}
          />
        </div>
      )}
    </>
  );
};
