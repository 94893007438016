import {Typography} from '~library/atoms/Typography';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';

import './OptionGroup.sass';

export const OptionGroup = ({title, description, children}) => {
  const isLargishAndUp = useIsLargishAndUp();

  return (
    <div className="sheet-options__option-group">
      <Typography variant="paragraph1" semibold>
        {title}
      </Typography>
      {description && (
        <Typography
          variant={isLargishAndUp ? 'paragraph2' : 'paragraph3'}
          color="pebble"
          className="sheet-options__option-group__description"
        >
          {description}
        </Typography>
      )}
      {children}
    </div>
  );
};
