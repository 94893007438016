import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {DocumentIcon} from '~library/svg/DocumentIcon';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';
import {useLazyExportSheetToCSVQuery} from '~/SignUpSheets/api/sheet';
import {useSheetOptions} from '~/SignUpSheets/components/Header/components/SheetOptions/context/SheetOptionsContext';
import {SheetOption} from '~/SignUpSheets/components/Header/components/SheetOptions/components/SheetOption/SheetOption';

export const ExportList = () => {
  const sheetId = useSelector(selectSheetId);
  const [exportSheetToCSV] = useLazyExportSheetToCSVQuery();
  const {closeOptions} = useSheetOptions();

  const onExportSheetToCSV = useCallback(async () => {
    if (!sheetId) return;
    await exportSheetToCSV(sheetId);
    closeOptions();
  }, [sheetId, exportSheetToCSV, closeOptions]);

  return (
    <SheetOption
      title="Export List"
      description="Download a CSV of all your signups and volunteers."
      icon={<DocumentIcon ratio={0.8} />}
      onClick={onExportSheetToCSV}
    />
  );
};
